import { Icon } from '@chakra-ui/react';

export const IconRemoveBackground = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.71254 7.83777L8.33742 5.21293L11.3898 8.26535L8.765 10.8902L5.71254 7.83777ZM12.2738 9.14883L9.64922 11.7738L16.5938 18.75L19.25 16.0938L12.2738 9.14883ZM1.75 6.875H4.875V8.125H1.75V6.875ZM3.14156 3.52539L4.02543 2.64148L6.23516 4.85121L5.35129 5.73508L3.14156 3.52539ZM7.375 1.25H8.625V4.375H7.375V1.25ZM9.77031 4.85137L11.98 2.64164L12.8639 3.52551L10.6542 5.73523L9.77031 4.85137ZM3.14141 11.48L5.35113 9.27023L6.235 10.1541L4.02527 12.3638L3.14141 11.48Z"
      fill="black"
    />
  </svg>
);

export const IconLayerUp = () => (
  <Icon
    width="21px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 2.5C0.5 1.83696 0.763392 1.20107 1.23223 0.732233C1.70107 0.263392 2.33696 0 3 0L13 0C13.663 0 14.2989 0.263392 14.7678 0.732233C15.2366 1.20107 15.5 1.83696 15.5 2.5V5H18C18.663 5 19.2989 5.26339 19.7678 5.73223C20.2366 6.20107 20.5 6.83696 20.5 7.5V17.5C20.5 18.163 20.2366 18.7989 19.7678 19.2678C19.2989 19.7366 18.663 20 18 20H8C7.33696 20 6.70107 19.7366 6.23223 19.2678C5.76339 18.7989 5.5 18.163 5.5 17.5V15H3C2.33696 15 1.70107 14.7366 1.23223 14.2678C0.763392 13.7989 0.5 13.163 0.5 12.5V2.5ZM6.75 15V17.5C6.75 17.8315 6.8817 18.1495 7.11612 18.3839C7.35054 18.6183 7.66848 18.75 8 18.75H18C18.3315 18.75 18.6495 18.6183 18.8839 18.3839C19.1183 18.1495 19.25 17.8315 19.25 17.5V7.5C19.25 7.16848 19.1183 6.85054 18.8839 6.61612C18.6495 6.3817 18.3315 6.25 18 6.25H15.5V12.5C15.5 13.163 15.2366 13.7989 14.7678 14.2678C14.2989 14.7366 13.663 15 13 15H6.75Z"
      fill="black"
    />
  </Icon>
);

export const IconLayerDown = () => (
  <Icon
    width="21px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 2.5C0.5 1.83696 0.763392 1.20107 1.23223 0.732233C1.70107 0.263392 2.33696 0 3 0L13 0C13.663 0 14.2989 0.263392 14.7678 0.732233C15.2366 1.20107 15.5 1.83696 15.5 2.5V5H18C18.663 5 19.2989 5.26339 19.7678 5.73223C20.2366 6.20107 20.5 6.83696 20.5 7.5V17.5C20.5 18.163 20.2366 18.7989 19.7678 19.2678C19.2989 19.7366 18.663 20 18 20H8C7.33696 20 6.70107 19.7366 6.23223 19.2678C5.76339 18.7989 5.5 18.163 5.5 17.5V15H3C2.33696 15 1.70107 14.7366 1.23223 14.2678C0.763392 13.7989 0.5 13.163 0.5 12.5V2.5ZM3 1.25C2.66848 1.25 2.35054 1.3817 2.11612 1.61612C1.8817 1.85054 1.75 2.16848 1.75 2.5V12.5C1.75 12.8315 1.8817 13.1495 2.11612 13.3839C2.35054 13.6183 2.66848 13.75 3 13.75H13C13.3315 13.75 13.6495 13.6183 13.8839 13.3839C14.1183 13.1495 14.25 12.8315 14.25 12.5V2.5C14.25 2.16848 14.1183 1.85054 13.8839 1.61612C13.6495 1.3817 13.3315 1.25 13 1.25H3Z"
      fill="black"
    />
  </Icon>
);

export const IconEraser = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1074 2.75882C10.5762 2.29014 11.2119 2.02686 11.8749 2.02686C12.5378 2.02686 13.1735 2.29014 13.6424 2.75882L18.4911 7.60757C18.9598 8.07639 19.2231 8.71216 19.2231 9.37507C19.2231 10.038 18.9598 10.6738 18.4911 11.1426L11.6161 18.0176C11.1474 18.4864 10.5116 18.7499 9.84861 18.7501H6.39986C5.73687 18.7499 5.10109 18.4864 4.63236 18.0176L1.50736 14.8926C1.03868 14.4238 0.775391 13.788 0.775391 13.1251C0.775391 12.4622 1.03868 11.8264 1.50736 11.3576L10.1061 2.75882H10.1074ZM10.9324 16.9338L4.31611 10.3176L2.39236 12.2413C2.15802 12.4757 2.02637 12.7936 2.02637 13.1251C2.02637 13.4565 2.15802 13.7744 2.39236 14.0088L5.51736 17.1338C5.75172 17.3683 6.06961 17.5 6.40111 17.5001H9.84986C10.1814 17.5 10.4992 17.3683 10.7336 17.1338L10.9336 16.9338H10.9324Z"
      fill="black"
    />
  </svg>
);

export const IconCopy = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33333 3.33341C8.11232 3.33341 7.90036 3.42121 7.74408 3.57749C7.5878 3.73377 7.5 3.94573 7.5 4.16675V12.5001C7.5 12.7211 7.5878 12.9331 7.74408 13.0893C7.90036 13.2456 8.11232 13.3334 8.33333 13.3334H15C15.221 13.3334 15.433 13.2456 15.5893 13.0893C15.7455 12.9331 15.8333 12.7211 15.8333 12.5001V7.01193L12.1549 3.3335L8.33333 3.33341ZM14.1667 15.0001V15.8334C14.1667 16.4965 13.9033 17.1323 13.4344 17.6012C12.9656 18.07 12.3297 18.3334 11.6667 18.3334H5C4.33696 18.3334 3.70107 18.07 3.23223 17.6012C2.76339 17.1323 2.5 16.4965 2.5 15.8334V7.50008C2.5 6.83704 2.76339 6.20116 3.23223 5.73231C3.70107 5.26347 4.33696 5.00008 5 5.00008H5.83333V4.16675C5.83333 3.50371 6.09673 2.86782 6.56557 2.39898C7.03441 1.93014 7.67029 1.66675 8.33333 1.66675H12.155C12.5969 1.66684 13.0209 1.84247 13.3334 2.15499M13.3334 2.15499L17.0117 5.83324C17.0116 5.83321 17.0117 5.83327 17.0117 5.83324C17.3242 6.14572 17.4999 6.56962 17.5 7.01157V12.5001C17.5 13.1631 17.2366 13.799 16.7678 14.2678C16.2989 14.7367 15.663 15.0001 15 15.0001H14.1667M5.83333 6.66675H5C4.77899 6.66675 4.56702 6.75455 4.41074 6.91083C4.25446 7.06711 4.16667 7.27907 4.16667 7.50008V15.8334C4.16667 16.0544 4.25446 16.2664 4.41074 16.4227C4.56702 16.579 4.77899 16.6667 5 16.6667H11.6667C11.8877 16.6667 12.0996 16.579 12.2559 16.4227C12.4122 16.2664 12.5 16.0544 12.5 15.8334V15.0001H8.33333C7.67029 15.0001 7.03441 14.7367 6.56557 14.2678C6.09673 13.799 5.83333 13.1631 5.83333 12.5001V6.66675Z"
      fill="black"
    />
  </svg>
);

export const IconCrop = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.76496 0.943197C4.0628 0.945778 4.30215 1.18931 4.29957 1.48715L4.22948 9.5739C4.22999 9.71623 4.28675 9.85263 4.38743 9.95331C4.48857 10.0544 4.62575 10.1113 4.76878 10.1113H12.8583C13.1561 10.1113 13.3976 10.3527 13.3976 10.6506C13.3976 10.9484 13.1561 11.1899 12.8583 11.1899H4.76878C4.33968 11.1899 3.92816 11.0194 3.62475 10.716C3.32134 10.4126 3.15088 10.0011 3.15088 9.57196V9.56729L3.1509 9.56729L3.22101 1.4778C3.22359 1.17997 3.46713 0.940616 3.76496 0.943197Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.08313 3.63971C9.51223 3.63971 9.92374 3.81017 10.2272 4.11358C10.5306 4.417 10.701 4.82851 10.701 5.25761V13.3471C10.701 13.6449 10.4596 13.8864 10.1617 13.8864C9.86389 13.8864 9.62243 13.6449 9.62243 13.3471V5.25761C9.62243 5.11458 9.56561 4.9774 9.46448 4.87626C9.36379 4.77558 9.2274 4.71882 9.08507 4.71831L0.998319 4.7884C0.700484 4.79098 0.456948 4.55163 0.454366 4.25379C0.451785 3.95596 0.691136 3.71242 0.988972 3.70984L9.08313 3.63971Z"
      fill="black"
    />
  </Icon>
);

export const IconTrash = () => (
  <svg
    width="21px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.375 6.875C7.54076 6.875 7.69973 6.94085 7.81694 7.05806C7.93415 7.17527 8 7.33424 8 7.5V15C8 15.1658 7.93415 15.3247 7.81694 15.4419C7.69973 15.5592 7.54076 15.625 7.375 15.625C7.20924 15.625 7.05027 15.5592 6.93306 15.4419C6.81585 15.3247 6.75 15.1658 6.75 15V7.5C6.75 7.33424 6.81585 7.17527 6.93306 7.05806C7.05027 6.94085 7.20924 6.875 7.375 6.875ZM10.5 6.875C10.6658 6.875 10.8247 6.94085 10.9419 7.05806C11.0592 7.17527 11.125 7.33424 11.125 7.5V15C11.125 15.1658 11.0592 15.3247 10.9419 15.4419C10.8247 15.5592 10.6658 15.625 10.5 15.625C10.3342 15.625 10.1753 15.5592 10.0581 15.4419C9.94085 15.3247 9.875 15.1658 9.875 15V7.5C9.875 7.33424 9.94085 7.17527 10.0581 7.05806C10.1753 6.94085 10.3342 6.875 10.5 6.875ZM14.25 7.5C14.25 7.33424 14.1842 7.17527 14.0669 7.05806C13.9497 6.94085 13.7908 6.875 13.625 6.875C13.4592 6.875 13.3003 6.94085 13.1831 7.05806C13.0658 7.17527 13 7.33424 13 7.5V15C13 15.1658 13.0658 15.3247 13.1831 15.4419C13.3003 15.5592 13.4592 15.625 13.625 15.625C13.7908 15.625 13.9497 15.5592 14.0669 15.4419C14.1842 15.3247 14.25 15.1658 14.25 15V7.5Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.625 3.75C18.625 4.08152 18.4933 4.39946 18.2589 4.63388C18.0245 4.8683 17.7065 5 17.375 5H16.75V16.25C16.75 16.913 16.4866 17.5489 16.0178 18.0178C15.5489 18.4866 14.913 18.75 14.25 18.75H6.75C6.08696 18.75 5.45107 18.4866 4.98223 18.0178C4.51339 17.5489 4.25 16.913 4.25 16.25V5H3.625C3.29348 5 2.97554 4.8683 2.74112 4.63388C2.5067 4.39946 2.375 4.08152 2.375 3.75V2.5C2.375 2.16848 2.5067 1.85054 2.74112 1.61612C2.97554 1.3817 3.29348 1.25 3.625 1.25H8C8 0.918479 8.1317 0.600537 8.36612 0.366117C8.60054 0.131696 8.91848 0 9.25 0L11.75 0C12.0815 0 12.3995 0.131696 12.6339 0.366117C12.8683 0.600537 13 0.918479 13 1.25H17.375C17.7065 1.25 18.0245 1.3817 18.2589 1.61612C18.4933 1.85054 18.625 2.16848 18.625 2.5V3.75ZM5.6475 5L5.5 5.07375V16.25C5.5 16.5815 5.6317 16.8995 5.86612 17.1339C6.10054 17.3683 6.41848 17.5 6.75 17.5H14.25C14.5815 17.5 14.8995 17.3683 15.1339 17.1339C15.3683 16.8995 15.5 16.5815 15.5 16.25V5.07375L15.3525 5H5.6475ZM3.625 3.75V2.5H17.375V3.75H3.625Z"
      fill="black"
    />
  </svg>
);

export const IconColorPicker = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.53156 4.37965C6.40489 2.71926 8.82754 1.875 11.1489 1.875C13.1376 1.875 15.0855 2.49614 16.554 3.78864L16.555 3.78946C18.6488 5.63958 18.649 8.70326 16.5557 10.5537C16.5554 10.5539 16.5552 10.5541 16.555 10.5543L15.3725 11.604C15.3725 11.6041 15.3726 11.604 15.3725 11.604C15.208 11.7502 15.1636 11.8908 15.1636 11.9922C15.1636 12.0936 15.208 12.2342 15.3725 12.3803L15.3745 12.3821C15.4804 12.4769 15.5341 12.5102 15.5842 12.5327C15.6428 12.5589 15.7357 12.5875 15.9422 12.6216L15.9443 12.622C15.9618 12.6249 15.9807 12.628 16.0008 12.6313C16.291 12.6786 16.8315 12.7667 17.2255 13.1309L16.8013 13.5898L17.2278 13.133C17.6005 13.4809 17.7934 13.9901 17.8091 14.4779C17.8251 14.9739 17.6592 15.5258 17.2117 15.921C15.5622 17.3873 13.2732 18.125 10.9849 18.125H10.9139C8.62332 18.1085 6.28298 17.3528 4.53176 15.8041C0.988829 12.6726 0.988761 7.51122 4.53156 4.37965ZM16.3759 14.0478C16.2814 13.9613 16.1124 13.918 15.7375 13.8548C15.4952 13.8147 15.2779 13.7652 15.073 13.6734C14.8599 13.5779 14.6971 13.4533 14.5416 13.3142C14.1481 12.9643 13.9136 12.4979 13.9136 11.9922C13.9136 11.4861 14.1485 11.0194 14.5425 10.6694L15.7262 9.61855L15.7273 9.61758C17.258 8.26496 17.2582 6.07949 15.7278 4.72666C14.5245 3.66781 12.8866 3.125 11.1489 3.125C9.11889 3.125 6.99481 3.86658 5.36038 5.31536L5.3597 5.31596C2.37945 7.95001 2.37945 12.2336 5.3597 14.8676C6.85015 16.1857 8.88403 16.8598 10.9208 16.875H10.9849C13.0242 16.875 15.0004 16.2146 16.3821 14.9861L16.384 14.9844C16.4982 14.8836 16.5663 14.721 16.5597 14.5182C16.553 14.3077 16.4672 14.1338 16.3759 14.0478Z"
      fill="black"
    />
    <path
      d="M5.625 9.375C6.31536 9.375 6.875 8.81536 6.875 8.125C6.875 7.43464 6.31536 6.875 5.625 6.875C4.93464 6.875 4.375 7.43464 4.375 8.125C4.375 8.81536 4.93464 9.375 5.625 9.375Z"
      fill="black"
    />
    <path
      d="M5.9375 13.3984C6.62786 13.3984 7.1875 12.8388 7.1875 12.1484C7.1875 11.4581 6.62786 10.8984 5.9375 10.8984C5.24714 10.8984 4.6875 11.4581 4.6875 12.1484C4.6875 12.8388 5.24714 13.3984 5.9375 13.3984Z"
      fill="black"
    />
    <path
      d="M8.75 6.875C9.44036 6.875 10 6.31536 10 5.625C10 4.93464 9.44036 4.375 8.75 4.375C8.05964 4.375 7.5 4.93464 7.5 5.625C7.5 6.31536 8.05964 6.875 8.75 6.875Z"
      fill="black"
    />
    <path
      d="M10 16.2109C11.0355 16.2109 11.875 15.3715 11.875 14.3359C11.875 13.3004 11.0355 12.4609 10 12.4609C8.96447 12.4609 8.125 13.3004 8.125 14.3359C8.125 15.3715 8.96447 16.2109 10 16.2109Z"
      fill="black"
    />
    <path
      d="M12.8125 6.875C13.5029 6.875 14.0625 6.31536 14.0625 5.625C14.0625 4.93464 13.5029 4.375 12.8125 4.375C12.1221 4.375 11.5625 4.93464 11.5625 5.625C11.5625 6.31536 12.1221 6.875 12.8125 6.875Z"
      fill="black"
    />
  </Icon>
);

export const IconFontFamily = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.6415 12.9167H12.3415L13.2915 15.4167H15.0332L10.7748 4.58341H9.22484L4.9665 15.4167H6.70817L7.6415 12.9167V12.9167ZM9.99984 6.65008L11.7248 11.2501H8.27484L9.99984 6.65008ZM16.6665 1.66675H3.33317C2.4165 1.66675 1.6665 2.41675 1.6665 3.33341V16.6667C1.6665 17.5834 2.4165 18.3334 3.33317 18.3334H16.6665C17.5832 18.3334 18.3332 17.5834 18.3332 16.6667V3.33341C18.3332 2.41675 17.5832 1.66675 16.6665 1.66675ZM16.6665 16.6667H3.33317V3.33341H16.6665V16.6667Z"
      fill="black"
    />
  </Icon>
);

export const IconFontSize = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 3.33325V5.83325H11.6667V15.8333H14.1667V5.83325H18.3333V3.33325H7.5ZM2.5 9.99992H5V15.8333H7.5V9.99992H10V7.49992H2.5V9.99992Z"
      fill="black"
    />
  </Icon>
);

export const IconTextAlign = () => (
  <Icon
    width="21px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.8335 15.8333H17.1668V17.5H3.8335V15.8333ZM3.8335 12.5H13.0002V14.1667H3.8335V12.5ZM3.8335 9.16667H17.1668V10.8333H3.8335V9.16667ZM3.8335 2.5H17.1668V4.16667H3.8335V2.5ZM3.8335 5.83333H13.0002V7.5H3.8335V5.83333Z"
      fill="black"
    />
  </Icon>
);

export const IconTextLeftAlign = ({ isSelected = false }) => (
  <Icon
    width="21px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.83337 15.8333H17.1667V17.5H3.83337V15.8333ZM3.83337 12.5H13V14.1667H3.83337V12.5ZM3.83337 9.16667H17.1667V10.8333H3.83337V9.16667ZM3.83337 2.5H17.1667V4.16667H3.83337V2.5ZM3.83337 5.83333H13V7.5H3.83337V5.83333Z"
      fill={isSelected ? '#000000' : '#959392'}
    />
  </Icon>
);

export const IconTextCenter = ({ isSelected = false }) => (
  <Icon
    width="22px"
    height="23px"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66187 9.72328C4.66187 9.23268 5.05958 8.83496 5.55019 8.83496H16.21C16.7006 8.83496 17.0984 9.23268 17.0984 9.72328C17.0984 10.2139 16.7006 10.6116 16.21 10.6116H5.55019C5.05958 10.6116 4.66187 10.2139 4.66187 9.72328Z"
      fill={isSelected ? '#000000' : '#959392'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99683 6.17006C1.99683 5.67945 2.39454 5.28174 2.88515 5.28174H18.8749C19.3655 5.28174 19.7633 5.67945 19.7633 6.17006C19.7633 6.66067 19.3655 7.05838 18.8749 7.05838H2.88515C2.39454 7.05838 1.99683 6.66067 1.99683 6.17006Z"
      fill={isSelected ? '#000000' : '#959392'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99683 13.2765C1.99683 12.7859 2.39454 12.3882 2.88515 12.3882H18.8749C19.3655 12.3882 19.7633 12.7859 19.7633 13.2765C19.7633 13.7671 19.3655 14.1648 18.8749 14.1648H2.88515C2.39454 14.1648 1.99683 13.7671 1.99683 13.2765Z"
      fill={isSelected ? '#000000' : '#959392'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66187 16.83C4.66187 16.3394 5.05958 15.9417 5.55019 15.9417H16.21C16.7006 15.9417 17.0984 16.3394 17.0984 16.83C17.0984 17.3206 16.7006 17.7183 16.21 17.7183H5.55019C5.05958 17.7183 4.66187 17.3206 4.66187 16.83Z"
      fill={isSelected ? '#000000' : '#959392'}
    />
  </Icon>
);

export const IconTextRightAlign = ({ isSelected = false }) => (
  <Icon
    width="23px"
    height="23px"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29688 9.72328C6.29688 9.23268 6.69459 8.83496 7.1852 8.83496H19.6217C20.1123 8.83496 20.51 9.23268 20.51 9.72328C20.51 10.2139 20.1123 10.6116 19.6217 10.6116H7.1852C6.69459 10.6116 6.29688 10.2139 6.29688 9.72328Z"
      fill={isSelected ? '#000000' : '#959392'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.74353 6.17006C2.74353 5.67945 3.14125 5.28174 3.63185 5.28174H19.6216C20.1122 5.28174 20.51 5.67945 20.51 6.17006C20.51 6.66067 20.1122 7.05838 19.6216 7.05838H3.63185C3.14125 7.05838 2.74353 6.66067 2.74353 6.17006Z"
      fill={isSelected ? '#000000' : '#959392'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.74353 13.2765C2.74353 12.7859 3.14125 12.3882 3.63185 12.3882H19.6216C20.1122 12.3882 20.51 12.7859 20.51 13.2765C20.51 13.7671 20.1122 14.1648 19.6216 14.1648H3.63185C3.14125 14.1648 2.74353 13.7671 2.74353 13.2765Z"
      fill={isSelected ? '#000000' : '#959392'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29688 16.83C6.29688 16.3394 6.69459 15.9417 7.1852 15.9417H19.6217C20.1123 15.9417 20.51 16.3394 20.51 16.83C20.51 17.3206 20.1123 17.7183 19.6217 17.7183H7.1852C6.69459 17.7183 6.29688 17.3206 6.29688 16.83Z"
      fill={isSelected ? '#000000' : '#959392'}
    />
  </Icon>
);

export const IconAiEdit = () => (
  <Icon
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3355_3090)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4399 1.3313L20.3471 5.63487L11.4686 14.3501L10.4178 13.2797L18.1428 5.69679L15.653 3.51325L9.11612 12.0025L7.92763 11.0873L15.4399 1.3313ZM5.41972 13.081C7.13769 11.9737 9.28988 12.7326 10.0228 14.2917C10.7634 15.8671 10.0081 17.7187 8.41591 18.651C6.79197 19.602 4.60976 19.5804 1.9619 18.1882L0.717773 17.534L1.95388 16.8648C2.80575 16.4036 3.12396 15.8992 3.48259 15.281C3.50102 15.2492 3.51959 15.2171 3.53835 15.1846C3.90946 14.5419 4.35772 13.7656 5.41972 13.081ZM8.66532 14.9299C8.31337 14.1812 7.17752 13.7326 6.23238 14.3418C5.47829 14.8279 5.1851 15.3342 4.82237 15.9607C4.80838 15.9848 4.79429 16.0092 4.78006 16.0337C4.53007 16.4646 4.24913 16.9284 3.78907 17.3744C5.54328 18.0406 6.79834 17.86 7.65795 17.3566C8.72357 16.7326 9.00964 15.6623 8.66532 14.9299Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3355_3090">
        <rect width="20" height="20" fill="white" transform="translate(0.425781 0.628113)" />
      </clipPath>
    </defs>
  </Icon>
);
