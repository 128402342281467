import IconLinkArrowRight from '@/lib/components/icons/IconLinkArrowRight';
import { Box, Button, Text } from '@chakra-ui/react';
import { useState } from 'react';

interface RoyaltiesBannerProps {
  onViewIps: () => void;
}

const RoyaltiesBanner = ({ onViewIps }: RoyaltiesBannerProps) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <Box
      bg="green.300"
      borderLeft="2px solid"
      borderLeftColor="green.600"
      p="10px 28px 12px 16px"
      w="100%"
    >
      <Text mb="6px" textStyle="body">
        You earn 15% from every single sale!
      </Text>
      <Button
        onClick={onViewIps}
        fontWeight={600}
        colorScheme="green"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        variant="link"
      >
        My IPs
        <IconLinkArrowRight color={isHovered && '#136400'} />
      </Button>
    </Box>
  );
};

export default RoyaltiesBanner;
