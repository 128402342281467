import { Box, HStack } from '@chakra-ui/react';

import ToolbarButton from '../components/ToolbarButton';

import IconUndo from '../../components/icons/IconUndo';
import IconRedo from '../../components/icons/IconRedo';
import { Canvas, CanvasObject, Template } from '../../types';

import ToolbarContainer from '../components/ToolbarContainer';

import Colors from '@/theme/colors';

import { IconAddText } from './Icons';
import HintPopover from '../components/hints/HintPopover';
import { getCanvasHintProps, Hint } from '../components/hints/hints';
import { QR_CODE_TYPE, QRCode } from '../fabric';

import IconQRCode from '../graphics-tools/icons/IconQRCode';
import IconGraphics from '../graphics-tools/icons/IconGraphics';
import ColorPicker from '../graphics-tools/ColorPicker';

const QR_CODE_SIZE = 200;

const textProps = {
  editable: true,
  fill: '#00FF29',
  fontFamily: 'Barrio-Regular',
  text: '',
  fontSize: 28,
  textAlign: 'left',
  originX: 'center',
  originY: 'center',
  scaleX: 3,
  scaleY: 3,
  centeredScaling: true,
};

type Props = {
  activeHint: Hint;
  onNextHint: (hint: Hint) => void;
  canvas: Canvas;
  selectedVariantId: string;
  onObjectAdded: (object: CanvasObject) => void;
  template: Template;
  onAddText: (props: object) => void;
  onToggleColorPicker: () => void;
  onToggleGraphicsPicker: () => void;
  onUndo?: () => void;
  onRedo?: () => void;
};

export default function Toolbar({
  activeHint,
  onNextHint,
  canvas,
  template,
  selectedVariantId,
  onObjectAdded,
  onAddText,
  onToggleColorPicker,
  onToggleGraphicsPicker,
  onUndo,
  onRedo,
}: Props) {
  const handleAddQRCode = () => {
    const { height, width } = canvas.clipPath || canvas;
    const { top, left } = canvas.clipPath || { top: 0, left: 0 };

    const qr = new QRCode({
      fill: 'transparent',
      stroke: Colors.gray,
      height: QR_CODE_SIZE,
      width: QR_CODE_SIZE,
      text: '',
      type: QR_CODE_TYPE,
      left: left + width / 2,
      top: top + height / 2,
      centeredScaling: true,
      originX: 'center',
      originY: 'center',
    });

    canvas.add(qr).renderAll();

    onObjectAdded(qr);
  };

  return (
    <ToolbarContainer>
      <HStack p="12px 11px 8px 12px" spacing="12px" w="100%">
        {onAddText ? (
          <ToolbarButton
            onClick={(e) => {
              e.stopPropagation();
              onAddText(textProps);
            }}
            icon={<IconAddText />}
            text="Add Text"
          />
        ) : null}
        <ColorPicker
          selectedVariantId={selectedVariantId}
          onToggleColorPicker={onToggleColorPicker}
          options={(template.colors || []).filter(({ isDeleted }) => !isDeleted)}
        />
        <HintPopover
          isOpen={activeHint === Hint.CUSTOM_QR_CODE}
          {...getCanvasHintProps(Hint.CUSTOM_QR_CODE)}
          onNext={() => onNextHint(Hint.CUSTOM_TEMPLATE)}
          onClose={() => onNextHint(null)}
        >
          <Box>
            <ToolbarButton
              icon={<IconQRCode />}
              isHighlighted={activeHint === Hint.CUSTOM_QR_CODE}
              onClick={handleAddQRCode}
              text="Add QR"
            />
          </Box>
        </HintPopover>
        <HintPopover
          isOpen={activeHint === Hint.CUSTOM_TEMPLATE}
          {...getCanvasHintProps(Hint.CUSTOM_TEMPLATE)}
          onNext={() => onNextHint(Hint.PINCH_ZOOM)}
          onClose={() => onNextHint(null)}
        >
          <Box>
            <ToolbarButton
              className="graphics-toggle-button"
              icon={<IconGraphics />}
              isHighlighted={activeHint === Hint.CUSTOM_TEMPLATE}
              onClick={onToggleGraphicsPicker}
              text="Graphics"
            />
          </Box>
        </HintPopover>
        <ToolbarButton
          isDisabled={!onUndo}
          icon={<IconUndo isDisabled={!onUndo} />}
          onClick={onUndo}
          text="Undo"
        />
        <ToolbarButton
          isDisabled={!onRedo}
          icon={<IconRedo isDisabled={!onRedo} />}
          onClick={onRedo}
          text="Redo"
        />
      </HStack>
    </ToolbarContainer>
  );
}
