import { useEffect, useState } from 'react';
import { Box, Button, Flex, HStack, Image, Text, VStack, useToast } from '@chakra-ui/react';

import { DragDropContext, Draggable } from 'react-beautiful-dnd';

import { isEmpty } from 'lodash';

import ColorWheel from '@/assets/ColorWheel.png';

import {
  createTemplateColor,
  createTemplateColorImage,
  deleteTemplateColor,
  updateTemplateColor,
  updateTemplateColorImage,
} from '@/api/templates';

import ButtonCTA from '@/components/button';
import ColorPickerModal from '@/components/color-picker/ColorPickerModal';
import Droppable from '@/components/drag-and-drop/Droppable';
import IconDragHandle from '@/components/drag-and-drop/IconDragHandle';
import FormInput from '@/components/form/FormInput';

import { TemplateColor, TemplateSide, Template } from '@/components/types';

import ButtonAddListItem from '../components/ButtonAddListItem';
import ButtonDelete from '../components/ButtonDelete';
import FormContainer from '../components/FormContainer';

import ImageUploadButton from '../components/ImageUploadButton';
import SizeVariantPicker from '../components/SizeVariantPicker';
import { getListStyle, getItemStyle, reorder } from '../components/DragAndDrop';

import { useSizes } from '@/api/sizes';
import { TemplateColorSize } from '@/lib';

const DEFAULT_COLOR = {
  hex: '',
  images: {},
  order: 0,
  name: '',
  sizes: [],
};

type ColorVariantsProps = {
  onNext: (updates: object) => void;
  onUpdate: (updates: object) => void;
  sides: TemplateSide[];
  variants: TemplateColor[];
  template: Template;
};

const ColorVariants = ({ onNext, onUpdate, sides, variants, template }: ColorVariantsProps) => {
  const [colors, setColors] = useState<TemplateColor[]>([]);
  const [pickingColorFor, setPickingColorFor] = useState<number | null>(null);
  const [waiting, setWaiting] = useState<number>(null);

  useEffect(() => {
    setColors(variants);
  }, [variants]);

  const toast = useToast();
  const { data: allSizes = [] } = useSizes();

  const handleAddColorVariant = () => {
    setColors([...colors, { ...DEFAULT_COLOR, order: colors.length }]);
  };

  const handleUpdateColorVariant = (indexToUpdate, updates) => {
    const newColors = colors.map((color, index) => {
      if (index === indexToUpdate) {
        return {
          ...color,
          ...updates,
        };
      }

      return color;
    });

    setColors(newColors);

    if (!updates.isDirty) {
      onUpdate(newColors);
    }
  };

  const handleSaveUpdatesForColor = (color, index) => {
    setWaiting(index);

    const { images } = color;

    updateTemplateColor(template.id, color)
      .then(() => {
        const sideIs = Object.keys(images).filter((sideId) => images[sideId]?.image);

        return Promise.all(
          sideIs.map((sideId) => {
            const { id, image } = images[sideId];

            if (id) {
              return updateTemplateColorImage(template.id, id, image);
            }

            return createTemplateColorImage(template.id, {
              templateSideId: sideId,
              templateColorId: color.id,
              image,
            });
          })
        ).then(() => {
          showToast();

          handleUpdateColorVariant(index, { images, isDirty: false });
        });
      })
      .finally(() => setWaiting(null));
  };

  const handleSaveNewColor = (color, index) => {
    if (!color.name) {
      return;
    }

    setWaiting(index);

    const { hex, images, name, stitchColor } = color;

    createTemplateColor(template.id, { hex, name, stitchColor })
      .then((newColor) => {
        const sideIds = Object.keys(images).filter((sideId) => images[sideId]);

        return Promise.all(
          sideIds.map((sideId) => {
            const { image } = images[sideId];

            return createTemplateColorImage(template.id, {
              templateSideId: sideId,
              templateColorId: newColor.id,
              image,
            });
          })
        ).then(() => {
          showToast();

          handleUpdateColorVariant(index, { ...newColor, isDirty: false });
        });
      })
      .finally(() => setWaiting(null));
  };

  const handleRemoveColor = (colorToRemove) => {
    if (template.id && colorToRemove.id) {
      deleteTemplateColor(template.id, colorToRemove.id).then(() =>
        handleColorRemoved(colorToRemove)
      );

      return;
    }

    handleColorRemoved(colorToRemove);
  };

  const handleColorRemoved = (colorToRemove) => {
    const newColors = colors.filter((color) => {
      return color !== colorToRemove;
    });

    onUpdate(newColors);

    setColors(newColors);
  };

  const handleImageUploaded = (index, side, image) => {
    const color = colors[index];
    const { images } = color;

    let newImages;

    if (template.id) {
      newImages = {
        ...images,
        [side.id]: {
          ...(images[side.id] || {}),
          image,
        },
      };
      handleSaveUpdatesForColor({ ...color, images: newImages }, index);
    } else {
      newImages = {
        ...images,
        [side.name]: image,
      };
    }

    handleUpdateColorVariant(index, { images: newImages, isDirty: !!template.id });
  };

  const handleSizesUpdate = (index: number, sizes: TemplateColorSize[]) => {
    handleUpdateColorVariant(index, {
      sizes,
      isDirty: true,
    });
  };

  const isNextDisabled =
    isEmpty(colors) ||
    !!colors.find((c) => !c?.sizes?.length) ||
    !!colors.find(({ hex, images }) => {
      return !hex || sides.find((side) => !images[side.name]);
    });

  const handleNext = () => {
    onNext(colors);
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newColors = reorder(colors, result.source.index, result.destination.index).map(
      (item, index) => ({
        ...item,
        order: index,
      })
    );

    Promise.all(newColors.map((color) => updateTemplateColor(template.id, color))).then(() => {
      showToast();
    });

    setColors(newColors);

    onUpdate(newColors);
  };

  const showToast = () =>
    toast({
      title: 'Changes saved',
      status: 'success',
    });

  return (
    <FormContainer
      description="File supported (PNG at 72dpi minimum)"
      nextDisabled={isNextDisabled}
      onNext={!template.id && handleNext}
      title="Color Variants"
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <div style={{ display: 'none' }}>{provided.placeholder}</div>
              <VStack align="flex-start" mt="20px" spacing="20px" w="600px">
                {colors.map((color, index) => {
                  const { hex, id, images, isDirty, name, stitchColor } = color;
                  let error;

                  if (!name) {
                    error = 'Enter a name.';
                  } else if (!hex) {
                    error = 'Pick a hex color by clicking on the color wheel.';
                  } else if (sides.find((side) => !images[side.name] && !images[side.id])) {
                    error = 'Upload an image for each template side.';
                  }

                  return (
                    <Draggable key={`key-${id}`} draggableId={`ID-${id}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          {provided.placeholder}
                          <Flex align="center" justify="space-between" key={index} w="100%" mb={8}>
                            <HStack key={index} spacing="14px">
                              <div {...provided.dragHandleProps}>
                                <IconDragHandle />
                              </div>
                              <VStack align="start">
                                <HStack>
                                  <VStack align="start">
                                    <HStack>
                                      <Box position="relative">
                                        <FormInput
                                          name="Color"
                                          placeholder="Ex) Blue"
                                          value={name}
                                          w="200px"
                                          onChange={(e) =>
                                            handleUpdateColorVariant(index, {
                                              name: e.target.value,
                                              isDirty: true,
                                            })
                                          }
                                        />
                                        <Button
                                          bg="transparent"
                                          minW="none"
                                          height="100%"
                                          onClick={() => setPickingColorFor(index)}
                                          p={0}
                                          position="absolute"
                                          top={0}
                                          right={0}
                                          _hover={{
                                            bg: 'none',
                                          }}
                                        >
                                          {hex ? (
                                            <Box
                                              h="24px"
                                              w="24px"
                                              borderRadius="50%"
                                              bg={hex}
                                            ></Box>
                                          ) : (
                                            <Image height="28px" width="28px" src={ColorWheel} />
                                          )}
                                        </Button>
                                      </Box>
                                      <FormInput
                                        name="Stitch Color"
                                        isOptional
                                        placeholder="(optional)"
                                        value={stitchColor}
                                        onChange={(e) =>
                                          handleUpdateColorVariant(index, {
                                            stitchColor: e.target.value,
                                            isDirty: true,
                                          })
                                        }
                                      />
                                    </HStack>
                                    {error ? (
                                      <Text
                                        color="red.400"
                                        fontWeight={400}
                                        position="relative"
                                        top="3px"
                                        textAlign="left"
                                      >
                                        {error}
                                      </Text>
                                    ) : null}
                                  </VStack>
                                  {sides.map(({ id, name }) => (
                                    <ImageUploadButton
                                      image={
                                        template.id
                                          ? images[id]?.image || images[id]?.url
                                          : images[name]
                                      }
                                      key={name}
                                      onImageUploaded={(image) =>
                                        handleImageUploaded(index, { id, name }, image)
                                      }
                                      title={name}
                                    />
                                  ))}
                                  {template.id && (!id || isDirty) ? (
                                    <ButtonCTA
                                      isDisabled={!name}
                                      isLoading={waiting === index}
                                      onClick={() =>
                                        id
                                          ? handleSaveUpdatesForColor(color, index)
                                          : handleSaveNewColor(color, index)
                                      }
                                      p={0}
                                      title="Save"
                                    >
                                      Save
                                    </ButtonCTA>
                                  ) : null}
                                  <ButtonDelete onClick={() => handleRemoveColor(color)} />
                                </HStack>
                                <VStack align="left" spacing="4px">
                                  <SizeVariantPicker
                                    allSizes={allSizes}
                                    onUpdateSelectedValues={(sizes) =>
                                      handleSizesUpdate(index, sizes)
                                    }
                                    selectedValues={color.sizes || []}
                                  />
                                </VStack>
                              </VStack>
                            </HStack>
                          </Flex>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                <ButtonAddListItem onClick={handleAddColorVariant}>
                  + Add more colors
                </ButtonAddListItem>
              </VStack>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {pickingColorFor !== null ? (
        <ColorPickerModal
          onClose={() => setPickingColorFor(null)}
          onSelectedColor={(color) => {
            handleUpdateColorVariant(pickingColorFor, { hex: color, isDirty: true });
          }}
          selectedColor={colors[pickingColorFor].hex}
        />
      ) : null}
    </FormContainer>
  );
};

export default ColorVariants;
