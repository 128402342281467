import { Icon } from '@chakra-ui/react';

const IconDots = () => (
  <Icon
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5517 15.15C18.2117 15.15 17.1254 14.0637 17.1254 12.7237C17.1254 11.3837 18.2117 10.2974 19.5517 10.2974C20.8917 10.2974 21.978 11.3837 21.978 12.7237C21.978 14.0637 20.8917 15.15 19.5517 15.15Z"
      fill="black"
    />
    <path
      d="M12.299 15.15C10.959 15.15 9.87268 14.0637 9.87268 12.7237C9.87268 11.3837 10.959 10.2974 12.299 10.2974C13.639 10.2974 14.7254 11.3837 14.7254 12.7237C14.7254 14.0637 13.639 15.15 12.299 15.15Z"
      fill="black"
    />
    <path
      d="M5.04634 15.15C3.70631 15.15 2.62001 14.0637 2.62001 12.7237C2.62001 11.3837 3.70631 10.2974 5.04634 10.2974C6.38637 10.2974 7.47268 11.3837 7.47268 12.7237C7.47268 14.0637 6.38637 15.15 5.04634 15.15Z"
      fill="black"
    />
  </Icon>
);

export default IconDots;
