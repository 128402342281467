import { Icon } from '@chakra-ui/react';

const IconCopyTrackingLink = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6.8"
      y="2.8"
      width="9.4"
      height="11.4"
      fill="white"
      stroke="#064AC4"
      stroke-width="1.6"
    />
    <rect
      x="3.8"
      y="5.8"
      width="9.4"
      height="11.4"
      fill="white"
      stroke="#064AC4"
      stroke-width="1.6"
    />
  </Icon>
);

export default IconCopyTrackingLink;
