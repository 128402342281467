import { OrderStatus } from '@/components/types';

import { Center, Text } from '@chakra-ui/react';

interface StatusBadgeProps {
  status: OrderStatus;
}

const StatusBadge = ({ status }: StatusBadgeProps) => {
  const { borderColor, text } = status;

  return (
    <Center
      borderRadius="4px"
      border="1px solid"
      borderColor={borderColor}
      color="secondaryDarkGray.600"
      padding="2px 4px"
    >
      <Text color={borderColor} textStyle="bodySuperSmall">
        {text}
      </Text>
    </Center>
  );
};

export default StatusBadge;
