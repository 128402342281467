import {
  Box,
  Center,
  Stack,
  Show,
  HStack,
  Text,
  Textarea,
  useToast,
  Hide,
  useBreakpointValue,
} from '@chakra-ui/react';

import IconBack from '@/lib/components/icons/IconBack';

import { Design, getDesignOutputImageFromJson } from '@/lib';
import { getDesign } from '@/api/designs';
import { useEffect, useState } from 'react';

import AppContainer from '@/layouts/AppContainer';
import { useHistory, useLocation } from 'react-router-dom';

import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { H2, H3 } from '@/components/typography/Headings';

import SubmitDesignPreviewGallery from './components/SubmitDesignPreviewGallery';
import IconChevronDownBreadCrumb from '@/components/icons/IconChevronBreadCrumb';
import StoryIcon from './icons/StoryIcon';
import FormInput from '@/components/form/FormInput';

import { getFullPrintableAreaImage } from '@/lib/utils/canvas-manufacturing-image-export';
import { updateBaseDesign, updateDesignSide } from '@/api/designs';
import { uploadBase64ToStorage } from '@/api/storage';
import { getCanvas } from '@/lib';
import Button from '@/components/button';

const TITLE = 'Publish your design';

export default function SubmitRemix() {
  const [values, setValues] = useState({ name: '', description: '' });

  const [savingDraft, setSavingDraft] = useState(false);
  const [isSubmittingDesign, setIsSubmittingDesign] = useState(false);

  const [design, setDesign] = useState<Design>(null);

  const [isLoading, setLoading] = useState(true);

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const designId = searchParams.get('designId');

  const history = useHistory();

  const toast = useToast();

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    getDesign(designId)
      .then((design) => {
        setDesign(design);

        const { name, description } = design;

        setValues({ name, description: description || '' });
      })
      .finally(() => setLoading(false));
  }, []);

  const handleGoBackToDesignStudio = () => history.push(`/designs/${design.id}`);

  const handleGoBackToReview = () => history.push(`/submit/review?designId=${design.id}`);

  const handleSubmit = async (isDraft = false) => {
    const { name, description } = values;

    if (!name) {
      return;
    }

    if (isDraft) {
      setSavingDraft(true);
    } else {
      setIsSubmittingDesign(true);
    }

    try {
      const { sides, template } = design;

      const sidesWithImages = await Promise.all(
        sides.map(async (side) => {
          if (!side.canvasState || !JSON.parse(side.canvasState)?.objects?.length) {
            return null;
          }

          const { canvasState, templateSide } = side;

          const canvas = getCanvas(templateSide, template, true);

          const designImage = await getDesignOutputImageFromJson(canvas, canvasState);

          const manufacturingImage = await getFullPrintableAreaImage(
            designImage,
            side.templateSide
          );

          const manufacturingImageUrl = await uploadBase64ToStorage(
            manufacturingImage,
            'image/png'
          );

          return {
            ...side,
            manufacturingImageUrl,
          };
        })
      );

      for (const side of sidesWithImages.filter((side) => !!side)) {
        const { id, manufacturingImageUrl } = side;

        await updateDesignSide(design.id, {
          id,
          manufacturingImageUrl,
        });
      }

      const redirectUrl = `${window.location.origin}/products/${design.id}?fromAcme=true`;

      const response = await updateBaseDesign({
        id: design.id,
        name,
        description: description || ' ',
        isPublished: true,
        redirectUrl,
      });

      if (isDraft) {
        toast({ title: 'Draft saved', status: 'success' });
      } else {
        const { acmeMintingLink } = response;

        if (!acmeMintingLink) {
          window.location.href = redirectUrl;

          return;
        }

        const link = acmeMintingLink;

        window.location.href = link;
      }
    } catch (e) {
      console.error(e);
      toast({ title: 'Error saving remix', status: 'error' });
    } finally {
      setSavingDraft(false);
      setIsSubmittingDesign(false);
    }
  };

  const { name, description } = values;

  return (
    <AppContainer contentContainerProps={{ p: 0 }}>
      {isLoading ? (
        <Center bg="transparent" h="100%">
          <LoadingSpinner />
        </Center>
      ) : (
        <Stack bg="#FFFFFF" h="100%" direction={{ base: 'column', md: 'row' }} spacing={0}>
          <Box
            bg="#FFFFFF"
            borderRight={{ base: 'none', md: '1px solid #E2E8F0' }}
            h={{ base: 'calc(100dvh - 157px)', md: '100%' }}
            overflow="auto"
            position="relative"
            p={{ base: '12px 16px 0 16px', md: '24px 32px' }}
            w={{ base: '100%', md: 404 }}
          >
            <Box h={{ base: '100%', md: 'calc(100% - 66px)' }} overflow="auto">
              <HStack gap="4px" mb={{ base: '28px', md: '27px' }}>
                <Text color="brand.600" onClick={handleGoBackToDesignStudio} textStyle="breadcrumb">
                  Design Studio
                </Text>
                <IconChevronDownBreadCrumb />
                {isMobile ? (
                  <>
                    <Text color="brand.600" onClick={handleGoBackToReview} textStyle="breadcrumb">
                      Review
                    </Text>
                    <IconChevronDownBreadCrumb />
                  </>
                ) : null}
                <Text color="secondaryDarkGray.600" fontWeight={400} textStyle="breadcrumb">
                  {isMobile ? 'Publish & Save' : 'Review'}
                </Text>
              </HStack>
              <HStack spacing="12px" mb={{ base: '8px', md: '13px' }}>
                <Button
                  aria-label="back"
                  borderRadius="5px"
                  icon={<IconBack h="16px" w="16px" />}
                  h="32px"
                  minW="auto"
                  padding="8px"
                  w="32px"
                  onClick={() => history.push(`/designs/${design.id}`)}
                  secondary
                >
                  <IconBack />
                </Button>
                <Hide above="md">
                  <H2>{TITLE}</H2>
                </Hide>
                <Show above="md">
                  <H3>{TITLE}</H3>
                </Show>
              </HStack>
              <FormInput
                name="Merch Name"
                description="[Graphic] – [Descriptor], e.g. Smiley – Bold Style"
                value={name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
              <Textarea
                placeholder="Description (optional)"
                rows={4}
                value={description}
                mb={{ base: '20px', md: '16px' }}
                mt={{ base: '18px', md: '20px' }}
                onChange={(e) => setValues({ ...values, description: e.target.value })}
              />
              <HStack alignSelf="flex-start" spacing="3px">
                <Text as="b" textTransform="uppercase" fontSize="xs">
                  Powered by
                </Text>
                <StoryIcon />
              </HStack>
            </Box>
            <HStack
              bottom={{ base: '27px', md: '60px', lg: '27px' }}
              left={{ base: '16px', md: '32px' }}
              right={{ base: '16px', md: '32px' }}
              position={{ base: 'fixed', md: 'absolute' }}
              spacing="12px"
            >
              <Button
                isLoading={isSubmittingDesign}
                isDisabled={!name}
                onClick={() => handleSubmit()}
                flex={1}
              >
                Publish Design
              </Button>
              <Button
                isLoading={savingDraft}
                isDisabled={!name}
                onClick={() => handleSubmit(true)}
                secondary
                flex={1}
              >
                Save Draft
              </Button>
            </HStack>
          </Box>
          <Show above="md">
            <Center flex={1}>
              <SubmitDesignPreviewGallery design={design} />
            </Center>
          </Show>
        </Stack>
      )}
    </AppContainer>
  );
}
