import { useEffect, useState } from 'react';
import { Center, Hide, Show, Stack, useBreakpointValue } from '@chakra-ui/react';

import DesignSideImage from './DesignSideImage';
import { Design } from '@/lib/types';
import DesignMockupImage from './DesignMockupImage';

const OVERFLOW_STYLE = {
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  'scrollbar-width': 'none',
};

interface DesignPreviewGalleryProps {
  design: Design;
}

const DesignPreviewGallery = ({ design }: DesignPreviewGalleryProps) => {
  const [selectedMockupImage, setSelectedMockupImage] = useState(null);
  const [selectedSide, setSelectedSide] = useState<string>(null);

  const { template } = design;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const selectSide = (side) => {
    setSelectedSide(side.name);
    setSelectedMockupImage(null);
  };

  const selectMockupImage = (mockupImage) => {
    setSelectedSide(null);
    setSelectedMockupImage(mockupImage);
  };

  useEffect(() => {
    if (!template) {
      return;
    }
    setSelectedSide(template?.sides[0]?.name);
  }, [template]);

  const thumnbailImageWidth = isMobile ? 82 : 86;

  return (
    <Stack
      align="flex-start"
      bg="#FFFFFF"
      borderRadius="10px"
      direction={{ base: 'column', md: 'row' }}
      justify={{ base: 'space-between', md: 'flex-start' }}
      h="100%"
      spacing="5px"
      w={{ base: '100%', md: 'auto' }}
    >
      <Hide above="md">
        {selectedSide && (
          <DesignSideImage
            design={design}
            sideName={selectedSide}
            imageWidth={265}
            height="auto"
            width="100%"
          />
        )}
        {selectedMockupImage && (
          <DesignMockupImage
            url={selectedMockupImage.url}
            imageWidth={265}
            height="auto"
            width="100%"
          />
        )}
      </Hide>
      {template.sides.length > 1 ? (
        <Stack
          direction={{ base: 'row', md: 'column' }}
          spacing={{ base: '5px', md: '8px' }}
          overflow="auto"
          css={OVERFLOW_STYLE}
          h={{ md: '529px' }}
          w={{ base: '100%', md: '88px' }}
        >
          {template.sides.map((side) => (
            <Center
              as="button"
              borderRadius={4}
              borderColor="secondaryDarkGray.600"
              borderWidth={selectedSide === side.name ? '1px' : 0}
              key={side.name}
              onClick={() => selectSide(side)}
            >
              <DesignSideImage
                design={design}
                sideName={side.name}
                imageWidth={64}
                isClickable
                height={isMobile ? 82 : 86}
                width={thumnbailImageWidth}
              />
            </Center>
          ))}
          {design.mockupImages?.map((mockupImage) => (
            <Center
              as="button"
              borderRadius={4}
              borderColor="secondaryDarkGray.600"
              borderWidth={selectedMockupImage === mockupImage.id ? '1px' : 0}
              key={mockupImage.id}
              onClick={() => selectMockupImage(mockupImage)}
            >
              <DesignMockupImage
                url={mockupImage.url}
                imageWidth={64}
                isClickable
                height={isMobile ? 82 : 86}
                width={thumnbailImageWidth}
              />
            </Center>
          ))}
        </Stack>
      ) : null}
      <Show above="md">
        {selectedSide && (
          <DesignSideImage
            design={design}
            sideName={selectedSide}
            imageWidth={520}
            height={529}
            width={540}
          />
        )}
        {selectedMockupImage && (
          <DesignMockupImage
            url={selectedMockupImage.url}
            imageWidth={520}
            height={529}
            width={540}
          />
        )}
      </Show>
    </Stack>
  );
};

export default DesignPreviewGallery;
