import { Icon } from '@chakra-ui/react';

const IconSmallMobile = ({ color, ...rest }) => (
  <Icon
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect y="-0.000976562" width="6.32406" height="6.32641" fill={color || '#D3DEEA'} />
    <rect x="7.67969" y="-0.000976562" width="6.32406" height="6.32641" fill={color || '#D3DEEA'} />
    <rect y="7.6748" width="6.32406" height="6.32641" fill={color || '#D3DEEA'} />
    <rect x="7.67969" y="7.6748" width="6.32406" height="6.32641" fill={color || '#D3DEEA'} />
  </Icon>
);

export default IconSmallMobile;
