import { Box } from '@chakra-ui/react';

const MaskCanvasContainer = ({ id }: { id: string }) => (
  <Box
    m="auto"
    right={0}
    position="absolute"
    top={0}
    bottom={0}
    left={0}
    h="max-content"
    w="max-content"
  >
    <canvas id={id}></canvas>
  </Box>
);

export default MaskCanvasContainer;
