import { Icon } from '@chakra-ui/react';

const IconLargeDesktop = ({ color, ...rest }) => (
  <Icon
    width="19px"
    height="18px"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x="0.984375" width="8.1332" height="8.1332" fill={color || '#D3DEEA'} />
    <rect x="10.8516" width="8.1332" height="8.1332" fill={color || '#D3DEEA'} />
    <rect x="0.984375" y="9.86621" width="8.1332" height="8.1332" fill={color || '#D3DEEA'} />
    <rect x="10.8516" y="9.86621" width="8.1332" height="8.1332" fill={color || '#D3DEEA'} />
  </Icon>
);

export default IconLargeDesktop;
