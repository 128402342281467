import { Icon } from '@chakra-ui/react';

const IconTemplateLibraryActive = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.52139 2.83009L1.27411 2.83009V8.40183L4.75089 8.40181V17.5324H15.3403V8.40181H18.7129V2.83008L13.4656 2.83008C13.4638 3.32304 13.377 3.81206 13.2087 4.27019C13.0387 4.73291 12.7883 5.15694 12.4691 5.51676C12.1499 5.8767 11.7677 6.16587 11.3424 6.36449C10.9169 6.56322 10.4584 6.66656 9.99347 6.66656C9.52858 6.66656 9.07 6.56322 8.64451 6.36449C8.21925 6.16587 7.837 5.8767 7.51779 5.51676C7.19868 5.15694 6.94825 4.73291 6.77827 4.27019C6.60997 3.81206 6.52309 3.32305 6.52139 2.83009Z"
      fill="#064AC4"
    />
    <path
      d="M12.4655 2.83008L7.5214 2.83009C7.5231 3.20726 7.58985 3.57941 7.71693 3.92537C7.84568 4.27583 8.03309 4.59068 8.26595 4.85325C8.49871 5.1157 8.77175 5.32023 9.06768 5.45844C9.36337 5.59655 9.67775 5.66656 9.99347 5.66656C10.3092 5.66656 10.6236 5.59655 10.9193 5.45844C11.2152 5.32023 11.4882 5.1157 11.721 4.85325C11.9538 4.59068 12.1413 4.27583 12.27 3.92537C12.3971 3.5794 12.4638 3.20726 12.4655 2.83008Z"
      fill="#064AC4"
    />
  </Icon>
);

export default IconTemplateLibraryActive;
