import { HStack, Text, VStack } from '@chakra-ui/react';

import Button from '@/components/button';
import { Design } from '@/lib';

import ModalContainer from '@/components/modals/ModalContainer';
import { H3 } from '../typography/Headings';
import { IconCheck } from '../icons/IconCheck';
import Config from '../../config';

type Props = {
  design: Design;
  onClose: () => void;
};

export const CrocsModal = ({ design, onClose }: Props) => {
  const handleGoToCrocs = () =>
    window.open(`${Config.CROCS_URL}?img_url=${design.sides[0].manufacturingImageUrl}`, '_blank');

  return (
    <ModalContainer
      onClose={onClose}
      width={{ base: 'calc(100% - 20px)', md: 677 }}
      isCloseButtonVisible={false}
    >
      <VStack spacing="10px" pt="96px" pb="96px" textAlign="center">
        <HStack justify="center">
          <IconCheck />
        </HStack>
        <H3>Looking good!</H3>
        <Text
          color="secondaryDarkGray.600"
          mb="18px"
          textStyle="body"
          w={{ base: 'calc(100% - 20px)', md: 340 }}
        >
          You will be redirected to Crocs.com to finalize your Jibbitz design and checkout.
        </Text>

        <Button onClick={handleGoToCrocs} w="289px">
          Go to Crocs.com
        </Button>
      </VStack>
    </ModalContainer>
  );
};
