import { Icon } from '@chakra-ui/react';

const IconTeam = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7279 4.2627C12.8269 4.2627 12.0964 4.99314 12.0964 5.8942C12.0964 6.79525 12.8269 7.5257 13.7279 7.5257C14.629 7.5257 15.3594 6.79525 15.3594 5.8942C15.3594 4.99314 14.629 4.2627 13.7279 4.2627ZM10.5964 5.8942C10.5964 4.16472 11.9985 2.7627 13.7279 2.7627C15.4574 2.7627 16.8594 4.16472 16.8594 5.8942C16.8594 7.62368 15.4574 9.0257 13.7279 9.0257C11.9985 9.0257 10.5964 7.62368 10.5964 5.8942Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64276 4.2627C5.74171 4.2627 5.01126 4.99314 5.01126 5.8942C5.01126 6.79525 5.74171 7.5257 6.64276 7.5257C7.54381 7.5257 8.27426 6.79525 8.27426 5.8942C8.27426 4.99314 7.54381 4.2627 6.64276 4.2627ZM3.51126 5.8942C3.51126 4.16472 4.91328 2.7627 6.64276 2.7627C8.37224 2.7627 9.77426 4.16472 9.77426 5.8942C9.77426 7.62368 8.37224 9.0257 6.64276 9.0257C4.91328 9.0257 3.51126 7.62368 3.51126 5.8942Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64275 11.5759C4.2111 11.5759 2.23987 13.5472 2.23987 15.9788V17.2382H0.739868V15.9788C0.739868 12.7187 3.38268 10.0759 6.64275 10.0759C9.90281 10.0759 12.5456 12.7187 12.5456 15.9788V17.2382H11.0456V15.9788C11.0456 13.5472 9.07439 11.5759 6.64275 11.5759Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3573 11.5759C12.303 11.5759 11.337 11.9455 10.579 12.5629L9.63174 11.3999C10.6476 10.5725 11.9454 10.0759 13.3573 10.0759C16.6173 10.0759 19.2601 12.7187 19.2601 15.9788V17.2382H17.7601V15.9788C17.7601 13.5472 15.7889 11.5759 13.3573 11.5759Z"
      fill="black"
    />
  </Icon>
);

export default IconTeam;
