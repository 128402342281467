import { Box, Spinner, Text } from '@chakra-ui/react';

import { useRef, useState } from 'react';

import Button from '../../../components/Button';

import StyleSelector from '../components/style-selector';

import ImagesPreview from '../components/ImagesPreview';
import { Favorite } from '@/lib';
import { DEFAULT_REFERENCE_IMAGE_STRENGTH } from '../../../../constants';
import { AbloSlider } from '../../components/AbloSlider';
import { CropTool } from '../../components/CropTool';
import getCroppedImg, { DEFAULT_CROP, DEFAULT_ZOOM } from '../image-to-image/cropImage';
import { IconAi } from '@/lib/components/icons/IconAi';
import CreditCostIcon from '../components/CreditCostIcon';
import PromptInput from '../components/PromptInput';
import { IAbloImage } from '@space-runners/ablo-ts-sdk/lib/interfaces/ablo-image.interface';
import { IStyle } from '@space-runners/ablo-ts-sdk/lib/services/style/style.interface';
import { IFontMakerRequest } from '@space-runners/ablo-ts-sdk/lib/services/font-maker/font-maker-request.interface';

const defaultParams = {
  styleId: '',
  text: '',
};

const SAMPLES = 1;

type FontToImageGeneratorProps = {
  actionCost?: number;
  onGeneratedImageSelected: (image: IAbloImage) => void;
  styles: IStyle[];
  generateImageFromFont: (options: IFontMakerRequest) => Promise<IAbloImage[]>;
  addFavorite: (favorite: Favorite) => void;
  favorites: Favorite[];
  removeFavorite: (id: string) => void;
};

export default function FontToImageGenerator({
  actionCost,
  onGeneratedImageSelected,
  styles,
  generateImageFromFont,
  addFavorite,
  favorites,
  removeFavorite,
}: FontToImageGeneratorProps) {
  const subjectInputRef = useRef(null);

  const [waiting, setWaiting] = useState(false);
  const [tooManyChars, setTooManyChars] = useState(false);
  const [options, setOptions] = useState<IFontMakerRequest>(defaultParams);
  const [referenceImageFile, setReferenceImageFile] = useState<{ file: File; objectUrl?: string }>(
    null
  );
  const [referenceImageStrength, setReferenceImageStrength] = useState(
    DEFAULT_REFERENCE_IMAGE_STRENGTH
  );
  const [selectedImage, setSelectedImage] = useState<IAbloImage>(null);
  const [isCropDone, setIsCropDone] = useState(false);
  const [crop, setCrop] = useState(DEFAULT_CROP);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [images, setImages] = useState([]);
  const [shouldBypassCache, setShouldBypassCache] = useState(false);

  const { styleId, text } = options;

  const MAX_CHARS = 25;

  const handlePlaceArtwork = () => {
    onGeneratedImageSelected({
      id: selectedImage.id,
      options,
      url: selectedImage.url,
    });
  };

  const handleReset = () => {
    setImages([]);
    setSelectedImage(null);

    setOptions({ ...defaultParams, text: options.text });
  };

  const handleUpdate = (updates) => {
    if (updates.text) {
      setTooManyChars(updates.text.length > MAX_CHARS);
    }

    setOptions({ ...options, ...updates });
    setShouldBypassCache(false);
  };

  const handleGenerate = () => {
    setWaiting(true);
    setImages([]);

    const requestParams: IFontMakerRequest = {
      styleId,
      text,
      shouldBypassCache,
      referenceImageFile: referenceImageFile?.file,
      ipAdapterScale: referenceImageStrength / 100,
      samples: SAMPLES,
    };

    generateImageFromFont(requestParams)
      .then((images) => {
        setWaiting(false);

        setImages(images);
        setSelectedImage(images[0]);
        setShouldBypassCache(true);
      })
      .catch(() => {
        setWaiting(false);
      });
  };

  const onSetCrop = async () => {
    const { file: croppedImage } = await getCroppedImg(
      referenceImageFile.objectUrl,
      croppedAreaPixels
    );

    setReferenceImageFile({
      file: new File([croppedImage], referenceImageFile.file.name, { type: 'image/png' }),
    });

    setCroppedAreaPixels(null);
    setIsCropDone(true);
    setCrop(DEFAULT_CROP);
    setZoom(DEFAULT_ZOOM);
  };

  const onCropComplete = (_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleReferenceImageFileChange = (file: File): void => {
    setReferenceImageFile(file ? { file, objectUrl: URL.createObjectURL(file) } : null);
    setCroppedAreaPixels(null);
    setIsCropDone(false);
    setCrop(DEFAULT_CROP);
    setZoom(DEFAULT_ZOOM);
  };

  const isDisabled = !text || !styleId || tooManyChars || waiting;

  return (
    <Box
      p="0 14px 26px 14px"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleGenerate();
        }
      }}
    >
      {images.length > 0 && (
        <Box p="0 14px 26px 14px">
          <ImagesPreview
            actionCost={actionCost * SAMPLES}
            images={images}
            selectedImage={selectedImage}
            onSelectedImage={setSelectedImage}
            onPlaceArtwork={handlePlaceArtwork}
            onGenerateSimilar={() => handleGenerate()}
            onNewArtwork={handleReset}
            showGenerateNewButtons={false}
            addFavorite={addFavorite}
            favorites={favorites}
            removeFavorite={removeFavorite}
          />
        </Box>
      )}
      <PromptInput
        onChange={(e) => handleUpdate({ text: e.target.value })}
        ref={subjectInputRef}
        value={text}
        placeholder={`Enter phrase (${MAX_CHARS} characters max)`}
      />
      <StyleSelector
        styles={styles}
        selectedStyleId={styleId}
        onSelectedStyle={(styleId) => {
          handleUpdate({ styleId });
        }}
        referenceImageFile={referenceImageFile?.file}
        onReferenceImageFileChange={handleReferenceImageFileChange}
      />

      {referenceImageFile && !isCropDone && (
        <CropTool
          crop={crop}
          imageFile={referenceImageFile.objectUrl}
          onCropComplete={onCropComplete}
          onCropChange={setCrop}
          zoom={zoom}
          onZoomChange={setZoom}
          onSetCrop={onSetCrop}
        />
      )}
      {referenceImageFile && (
        <Box mt="16px">
          <AbloSlider
            defaultValue={referenceImageStrength}
            min={1}
            max={100}
            step={1}
            height="2px"
            onChange={setReferenceImageStrength}
            value={referenceImageStrength}
            width="100%"
            label="Reference Image Strength"
          />
        </Box>
      )}

      {((!referenceImageFile && options.styleId !== undefined) ||
        (referenceImageFile && isCropDone)) && (
        <Box mt="22px">
          <Button
            iconRight={!isDisabled ? <CreditCostIcon cost={actionCost * SAMPLES} /> : null}
            isDisabled={isDisabled}
            onClick={() => handleGenerate()}
            title={shouldBypassCache ? 'Re-Generate' : 'Generate'}
            w="100%"
            icon={waiting ? <Spinner /> : <IconAi />}
          />
          {tooManyChars && (
            <Text color="red.500" fontSize="sm" mt="8px">
              {`Text must be ${MAX_CHARS} characters or less`}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
}
