import { Center, Text, VStack } from '@chakra-ui/react';

interface EmptyStateProps {
  searchTerm: string;
}

const EmptyState = ({ searchTerm }: EmptyStateProps) => (
  <Center border="1px solid" borderColor="borderSecondary" borderRadius="md" flex={1} w="100%">
    <VStack spacing="4px">
      <Text mb="1px" fontWeight={600} textStyle="body">
        No results{searchTerm ? ` for "${searchTerm}"` : ''}
      </Text>
      <Text color="secondaryDarkGray.600" textStyle="bodySmall">
        Try using different keywords or double-check for typos
      </Text>
    </VStack>
  </Center>
);

export default EmptyState;
