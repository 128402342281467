import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import IconCloseModal from '../icons/IconCloseModal';
import { H3 } from '../typography/Headings';

const CLOSE_BUTTON_SIZE = 24;

const CLOSE_BUTTON_MOBILE_OFFSET = 12;
const CLOSE_BUTTON_DESKTOP_OFFSET = 16;

interface ModalContainerProps {
  children: ReactNode;
  isCloseButtonVisible?: boolean;
  title?: string;
  onClose: () => void;
  width?: { base: number | string; md: number | string };
}

const ModalContainer = ({
  onClose,
  children,
  title,
  width,
  isCloseButtonVisible = true,
}: ModalContainerProps) => (
  <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
    <ModalOverlay background-color="rgba(27, 52, 97, 0.3)" background-blend-mode="multiply" />
    <ModalContent
      alignSelf="center"
      borderRadius={{ base: '14px', md: '16px' }}
      p={0}
      overflow="auto"
      h={{ base: 'calc(100dvh - 64px)', md: 'calc(100vh - 64px)' }}
      maxH="max-content"
      maxW={{ base: 'calc(100% - 32px)', md: '580px' }}
      mt={0}
      mb={0}
      width={width}
    >
      {isCloseButtonVisible && (
        <ModalCloseButton
          top={{ base: `${CLOSE_BUTTON_MOBILE_OFFSET}px`, md: `${CLOSE_BUTTON_DESKTOP_OFFSET}px` }}
          h={`${CLOSE_BUTTON_SIZE}px`}
          w={`${CLOSE_BUTTON_SIZE}px`}
          right={{
            base: `${CLOSE_BUTTON_MOBILE_OFFSET}px`,
            md: `${CLOSE_BUTTON_DESKTOP_OFFSET}px`,
          }}
          zIndex={2}
          _focusVisible={{
            boxShadow: 'none',
          }}
          _focus={{
            bg: 'none',
          }}
        >
          <IconCloseModal />
        </ModalCloseButton>
      )}
      <ModalBody
        display="flex"
        flexDirection="column"
        h="100%"
        padding={{ base: '16px', md: '24px' }}
        position="relative"
      >
        {title && (
          <H3 maxW="calc(100% - 40px)" mb={{ base: '4px', md: '6px' }}>
            {title}
          </H3>
        )}
        <Box h={{ base: 'calc(100% - 24px)', md: 'calc(100% - 32px)' }}>{children}</Box>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default ModalContainer;
