import { Button, HStack, useBreakpointValue } from '@chakra-ui/react';
import IconLargeMobile from './icons/IconLargeMobile';
import IconSmallDesktop from './icons/IconSmallDesktop';
import IconSmallMobile from './icons/IconSmallMobile';
import IconLargeDesktop from './icons/IconLargeDesktop';

const BUTTON_SIZE = 18;
const BUTTON_SIZE_MOBILE = 14;

interface CardsDisplayToggleProps {
  isLargeCardView?: boolean;
  onChange: (isLargeCardView: boolean) => void;
}

const CardsDisplayToggle = ({ isLargeCardView, onChange }: CardsDisplayToggleProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const options = isMobile
    ? [IconLargeMobile, IconSmallMobile]
    : [IconLargeDesktop, IconSmallDesktop];

  return (
    <HStack
      border="1px solid"
      borderColor="borderLight"
      borderRadius={5}
      gap="12px"
      p={{ base: '8px', md: '9px' }}
    >
      {options.map((IconComponent, index) => {
        const isSelected = index === (isLargeCardView ? 0 : 1);

        return (
          <Button
            aria-label="icon"
            h={{ base: `${BUTTON_SIZE_MOBILE}px`, md: `${BUTTON_SIZE}px` }}
            w={{ base: `${BUTTON_SIZE_MOBILE}px`, md: `${BUTTON_SIZE}px` }}
            minW="auto"
            onClick={() => onChange(index === 0)}
            p={0}
            px={0}
            variant="ghost"
          >
            <IconComponent color={isSelected && '#212529'} />
          </Button>
        );
      })}
    </HStack>
  );
};

export default CardsDisplayToggle;
