import axios from 'axios';

import { Cart, CartItem, ShippingInfo } from '@/components/types';

const URL = '/cart';
const URL_ORDERS = '/orders';

export const getCheckoutUrl = () => axios.get<string>(`${URL}/checkout`).then(({ data }) => data);

export const getCart = (): Promise<Cart> => axios.get<Cart>(URL).then(({ data }) => data);

export const getShippingInfo = (cartId: string) =>
  axios.get<ShippingInfo>(`${URL}/${cartId}/shipping`).then(({ data }) => data);

export const getOrders = async (): Promise<Cart[]> =>
  axios.get<Cart[]>(URL_ORDERS).then(async ({ data }) => data);

export const getOrder = (id: string) => axios.get<Cart>(`${URL}/${id}`).then(({ data }) => data);

export const deleteOrder = (id: string) =>
  axios.delete<Cart>(`${URL}/${id}`).then(({ data }) => data);

export const getOrderPayment = (id: string) =>
  axios.get(`${URL}/${id}/payment`).then(({ data }) => data);

export const getOrderShipping = (id: string) =>
  axios.get(`${URL}/${id}/shipping`).then(({ data }) => data);

export const createCartItem = (item: CartItem): Promise<CartItem> =>
  axios.post<CartItem>(`${URL}/item`, item).then(({ data }) => data);

export const updateCartItem = (id: string, quantity: number) =>
  axios.patch<CartItem>(`${URL}/item/${id}`, { quantity }).then(({ data }) => data);

export const deleteCartItem = (id: string) => axios.delete(`${URL}/item/${id}`);
