import { TrackingEvent } from '@/components/types';
import { H6 } from '@/components/typography/Headings';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';

const DOT_SIZE = 9;

interface TrackingEventBoxProps {
  isLastEvent: boolean;
  isEstimatedDelivery: boolean;
  trackingEvent: TrackingEvent;
}

const TrackingEventBox = ({
  trackingEvent,
  isEstimatedDelivery,
  isLastEvent,
}: TrackingEventBoxProps) => {
  const { description, triggered_at } = trackingEvent;

  return (
    <HStack align={isEstimatedDelivery ? 'flex-end' : 'flex-start'} spacing="10px">
      {isEstimatedDelivery ? (
        <VStack gap={0} pb="25px">
          <Box bg="borderLight" h="120px" w="1px" />
          <Box
            bg="borderLight"
            borderRadius="50%"
            height={`${DOT_SIZE}px`}
            width={`${DOT_SIZE}px`}
          />
        </VStack>
      ) : (
        <VStack gap={0}>
          <Box bg="brand.600" borderRadius="50%" height={`${DOT_SIZE}px`} width={`${DOT_SIZE}px`} />
          <Box bg={isLastEvent ? 'borderLight' : 'brand.600'} h="51px" w="1px" />
        </VStack>
      )}
      <VStack align="flex-start" h="100%" spacing={0}>
        <H6 position="relative" bottom="3px">
          {description}
        </H6>
        <Text color="secondaryDarkGray.600" textStyle="bodySuperSmall">
          {triggered_at ? format(parseISO(triggered_at), 'MMM dd, hh:mm') : null}
        </Text>
      </VStack>
    </HStack>
  );
};

export default TrackingEventBox;
