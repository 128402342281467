import { Icon } from '@chakra-ui/react';

const IconToggle = ({ isOpen, ...rest }) => (
  <Icon
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={isOpen ? '' : 'rotate(180deg)'}
    {...rest}
  >
    <path
      d="M3.93407 11.5112L9.94604 5.53516L15.958 11.5112L14.9983 12.4652L9.94604 7.44316L4.89381 12.4652L3.93407 11.5112Z"
      fill="#212529"
    />
  </Icon>
);

export default IconToggle;
