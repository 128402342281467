import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Payment } from '@/components/types';
import Panel from '@/components/panel';

interface OrderPaymentInfoProps {
  payment: Payment;
}

const OrderPaymentInfo = ({ payment }: OrderPaymentInfoProps) => {
  const { amount, billingAddress, paymentDetails, paymentIcon } = payment || {};

  return (
    <Panel title="Payment method">
      <HStack mt="21px" mb="29px" textStyle="body">
        <Image src={paymentIcon.url} />
        <Text color="secondaryDarkGray.600">{paymentDetails?.number} -</Text>
        <Text as="b">
          {amount.currencyCode} {amount.amount}
        </Text>
      </HStack>
      <VStack align="start" mt="13px" rowGap="0px">
        <Text textStyle="bodySuperSmall" as="b">
          Billing address
        </Text>
        <Box mt="8px" textStyle="bodySmall" color="secondaryDarkGray.600">
          <Text>{billingAddress.name}</Text>
          <Text>{billingAddress.address1}</Text>
          <Text>{billingAddress.city}</Text>
          <Text>{billingAddress.countryCodeV2}</Text>
        </Box>
      </VStack>
    </Panel>
  );
};

export default OrderPaymentInfo;
