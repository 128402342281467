import { InfoIcon } from '@chakra-ui/icons';
import { Icon, Box, Text } from '@chakra-ui/react';
import { globalStyles } from '../../theme/styles';

export default function CartDisclaimer() {
  return (
    <Box
      bg={globalStyles.colors.borderLight}
      borderRadius="6px"
      mb="16px"
      p="16px"
      display="flex"
      alignItems="flex-start"
    >
      <Icon as={InfoIcon} color="secondaryDarkGray.600" mr="12px" mt="2px" />
      <Box>
        <Text fontWeight={600} mb="4px" color="secondaryDarkGray.600" fontSize="14px">
          For Your Information
        </Text>
        <Text color="black.500" fontSize="14px">
          Due to Shopify restrictions, you may not be able to see your design on items on the
          checkout page. Please ensure that the final item includes your design.
        </Text>
      </Box>
    </Box>
  );
}
