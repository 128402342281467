import { TrackingInfo } from '@/components/types';
import { getFormattedAmount } from '@/views/admin/utils/currency-formatter';
import { HStack, Text, useToast, VStack } from '@chakra-ui/react';
import IconCopyTrackingLink from '../icons/IconCopyTrackingLink';
import TrackingInfoItem from './TrackingInfoItem';

interface TrackingInfoBoxProps {
  currency: string;
  orderNumber: number;
  trackingInfo: TrackingInfo;
  shippingPrice: number;
}

const TrackingInfoBox = ({
  orderNumber,
  trackingInfo,
  shippingPrice,
  currency,
}: TrackingInfoBoxProps) => {
  const toast = useToast();

  const { service, tracking_number } = trackingInfo || {};

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(tracking_number);

    toast({ title: 'Tracking number copied to clipboard', status: 'success' });
  };

  const infoItems = [
    {
      label: 'Order number:',
      value: `#${orderNumber}`,
    },
    {
      label: 'Shipping method:',
      value: service,
    },
    {
      label: 'Shipping price:',
      value: getFormattedAmount(currency, shippingPrice),
    },
    {
      label: 'Shipping method:',
      value: (
        <HStack cursor="pointer" onClick={copyToClipboard} spacing="7px">
          <Text color="brand.600" textStyle="bodySmall">
            {tracking_number}
          </Text>
          <IconCopyTrackingLink />
        </HStack>
      ),
    },
  ];

  return (
    <VStack align="flex-start">
      {infoItems.map(({ label, value }, index) => (
        <TrackingInfoItem
          isLast={index === infoItems.length - 1}
          label={label}
          value={value}
          key={index}
        />
      ))}
    </VStack>
  );
};

export default TrackingInfoBox;
