import { Icon } from '@chakra-ui/react';

const IconBrush = () => (
  <Icon
    width="21px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2875_2956)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5142 0.703186L20.4213 5.00676L11.5428 13.722L10.492 12.6516L18.217 5.06867L15.7272 2.88514L9.19034 11.3744L8.00185 10.4592L15.5142 0.703186ZM5.49393 12.4529C7.2119 11.3456 9.3641 12.1045 10.097 13.6636C10.8376 15.239 10.0823 17.0906 8.49013 18.0229C6.86619 18.9739 4.68398 18.9523 2.03612 17.5601L0.791992 16.9059L2.0281 16.2367C2.87997 15.7755 3.19818 15.2711 3.55681 14.6529C3.57524 14.6211 3.59381 14.589 3.61257 14.5565C3.98368 13.9138 4.43194 13.1375 5.49393 12.4529ZM8.73954 14.3018C8.38759 13.5531 7.25174 13.1045 6.3066 13.7137C5.55251 14.1998 5.25932 14.7061 4.89659 15.3326C4.8826 15.3567 4.8685 15.3811 4.85428 15.4056C4.60429 15.8365 4.32335 16.3003 3.86329 16.7462C5.6175 17.4124 6.87256 17.2319 7.73216 16.7285C8.79779 16.1045 9.08386 15.0342 8.73954 14.3018Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2875_2956">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconBrush;
