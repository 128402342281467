import { Icon } from '@chakra-ui/react';

const IconSmallDesktop = ({ color, ...rest }) => (
  <Icon
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x="-0.0078125" width="4.85031" height="4.84542" fill={color || '#D3DEEA'} />
    <rect x="6.57812" width="4.85031" height="4.84542" fill={color || '#D3DEEA'} />
    <rect x="13.1562" width="4.85031" height="4.84542" fill={color || '#D3DEEA'} />
    <rect x="-0.0078125" y="6.57617" width="4.85031" height="4.84542" fill={color || '#D3DEEA'} />
    <rect x="6.57812" y="6.57617" width="4.85031" height="4.84542" fill={color || '#D3DEEA'} />
    <rect x="13.1562" y="6.57617" width="4.85031" height="4.84542" fill={color || '#D3DEEA'} />
    <rect x="-0.0078125" y="13.1543" width="4.85031" height="4.84542" fill={color || '#D3DEEA'} />
    <rect x="6.57812" y="13.1543" width="4.85031" height="4.84542" fill={color || '#D3DEEA'} />
    <rect x="13.1562" y="13.1543" width="4.85031" height="4.84542" fill={color || '#D3DEEA'} />
  </Icon>
);

export default IconSmallDesktop;
