import { Icon } from '@chakra-ui/react';

const IconHomeActive = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4908 9.4442L10 1.10254L0.509277 9.4442H2.78067V18.552H8.22306V13.2212H11.777V18.552H17.2194V9.4442H19.4908Z"
      fill="#064AC4"
    />
  </Icon>
);

export default IconHomeActive;
