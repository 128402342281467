import { useEffect, useState } from 'react';
import { Box, Center, HStack } from '@chakra-ui/react';

import { Design } from '@/lib/types';
import SubmitDesignSideImage from './SubmitDesignSideImage';
import IconArrowRight from '@/lib/components/icons/IconArrowRight';
import { size } from 'lodash';
import Button from '@/components/button';

const THUMBNAIL_IMAGE_WIDTH = 82;

const ARROW_SIZE = 24;
const ARROW_BUTTON_SIZE = 44;

interface DesignPreviewGalleryProps {
  design: Design;
}

const SubmitDesignPreviewGallery = ({ design }: DesignPreviewGalleryProps) => {
  const [selectedSideIndex, setSelectedSideIndex] = useState<number>(0);

  const { template } = design;

  const isLeftDisabled = selectedSideIndex === 0;
  const isRightDisabled = selectedSideIndex === size(template.sides) - 1;

  useEffect(() => {
    if (!template) {
      return;
    }
    setSelectedSideIndex(0);
  }, [template]);

  return (
    <Center
      bg="secondaryGray.300"
      flexDirection="column"
      h="100%"
      position="relative"
      overflow="auto"
      w="100%"
    >
      <Box position="relative" w="67%">
        <SubmitDesignSideImage
          design={design}
          sideName={template.sides[selectedSideIndex].name}
          imageWidth="100%"
          width="100%"
        />
        <Button
          aria-label="back"
          isDisabled={isLeftDisabled}
          h={`${ARROW_BUTTON_SIZE}px`}
          w={`${ARROW_BUTTON_SIZE}px`}
          onClick={() => setSelectedSideIndex(selectedSideIndex - 1)}
          position="absolute"
          top={`calc(50% - ${ARROW_SIZE / 2}px)`}
          left={0}
          secondary
        >
          <IconArrowRight
            fill={isLeftDisabled && '#CBD6E3'}
            transform="rotate(180deg)"
            h={`${ARROW_SIZE}px`}
            w={`${ARROW_SIZE}px`}
          />
        </Button>
        <Button
          aria-label="next"
          isDisabled={isRightDisabled}
          h={`${ARROW_BUTTON_SIZE}px`}
          w={`${ARROW_BUTTON_SIZE}px`}
          onClick={() => setSelectedSideIndex(selectedSideIndex + 1)}
          position="absolute"
          top={`calc(50% - ${ARROW_SIZE / 2}px)`}
          right={0}
          secondary
        >
          <IconArrowRight
            fill={isRightDisabled && '#CBD6E3'}
            h={`${ARROW_SIZE}px`}
            w={`${ARROW_SIZE}px`}
          />
        </Button>
      </Box>
      {template.sides.length > 1 ? (
        <HStack position="relative" bottom="40px" spacing="5px">
          {template.sides.map((side, index) => {
            return (
              <Center
                as="button"
                borderRadius={4}
                borderColor="secondaryDarkGray.600"
                borderWidth={selectedSideIndex === index ? '1px' : 0}
                key={side.name}
                onClick={() => setSelectedSideIndex(index)}
                position="relative"
              >
                <SubmitDesignSideImage
                  design={design}
                  isClickable
                  sideName={side.name}
                  imageWidth="62px"
                  height={THUMBNAIL_IMAGE_WIDTH}
                  width={THUMBNAIL_IMAGE_WIDTH}
                />
              </Center>
            );
          })}
        </HStack>
      ) : null}
    </Center>
  );
};

export default SubmitDesignPreviewGallery;
