import { Box, HStack, Text } from '@chakra-ui/react';
import IconError from './IconError';

interface ErrorMessageProps {
  error: string;
}

const ErrorMessage = ({ error }: ErrorMessageProps) => (
  <Box padding="8px 14px">
    <HStack w="100%" borderRadius="9px" p="10px 16px 10px 16px" bg="#FBEDEC" spacing="5px">
      <IconError />
      <Text color="black.600" fontSize="sm">
        {error}
      </Text>
    </HStack>
  </Box>
);

export default ErrorMessage;
