import { Icon } from '@chakra-ui/react';

const IconDashboard = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.40723 10.5135V17.9215H6.40723V10.5135H1.40723ZM4.90723 12.0135H2.90723V16.4215H4.90723V12.0135ZM7.50006 17.9214H12.5001V0.931641H7.50006V17.9214ZM9.00006 2.43164V16.4214H11.0001V2.43164H9.00006ZM13.5929 17.9214L18.5929 17.9215V5.36862H13.5929V17.9214ZM15.0929 6.86861V16.4215H17.0929V6.86861H15.0929Z"
      fill="black"
    />
  </Icon>
);

export default IconDashboard;
