import { Icon } from '@chakra-ui/react';

const IconSearch = ({ color = null }) => (
  <Icon
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6 6.9C12.6 10.048 10.048 12.6 6.9 12.6C3.75198 12.6 1.2 10.048 1.2 6.9C1.2 3.75198 3.75198 1.2 6.9 1.2C10.048 1.2 12.6 3.75198 12.6 6.9ZM11.3082 12.2085C10.1127 13.2024 8.57613 13.8 6.9 13.8C3.08924 13.8 0 10.7108 0 6.9C0 3.08924 3.08924 0 6.9 0C10.7108 0 13.8 3.08924 13.8 6.9C13.8 8.60259 13.1833 10.1611 12.1612 11.3645L15.0578 14.2611C15.2921 14.4955 15.2921 14.8754 15.0578 15.1097C14.8235 15.344 14.4436 15.344 14.2093 15.1097L11.3082 12.2085Z"
      fill={color || '#626F82'}
    />
  </Icon>
);

export default IconSearch;
