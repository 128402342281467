import {
  Box,
  HStack,
  Popover,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { Route } from '../types';

import PopoverDemoHint from './PopoverDemoHint';
import IconArrowRight from '@/lib/components/icons/IconArrowRight';

import { useState } from 'react';
import Separator from './components/Separator';
import IconDots from '../icons/IconDots';

const SidebarText = ({ name, isActive }) => (
  <Text me="auto" color={isActive ? 'brand.600' : 'black.600'} fontWeight={600}>
    {name}
  </Text>
);

interface SidebarLinkProps {
  route: Route;
  isCollapsed: boolean;
  isDemoHintVisible?: boolean;
  onDismissDemoHint?: () => void;
}

const SidebarLink = ({
  route,
  isCollapsed,
  isDemoHintVisible,
  onDismissDemoHint,
}: SidebarLinkProps) => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(null);

  const isActiveRoute = (route) => {
    const { pathname } = location;

    if (!route.path) {
      return false;
    }

    const routePath = route.path.toLowerCase();

    const parts = pathname.split('/').slice(1);

    if (parts[0] === 'designs' && parts[1]) {
      return route.name === 'Generate Art';
    }

    if (route.exact) {
      return pathname === routePath;
    }

    return pathname.includes(routePath);
  };

  const { defaultPath, icon, iconActive, name, path, routes } = route;

  const isActive = isActiveRoute(route);

  const showsDemoHint = name === 'Generate Art' && isDemoHintVisible;

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  if (routes) {
    const isChildRouteActive = routes.find((route) => isActiveRoute(route));

    const isDropdownOpen = isOpen || (isChildRouteActive && isOpen !== false);

    return (
      <>
        <Separator pt={0} />
        <Box w="100%">
          <HStack
            cursor="pointer"
            justify="space-between"
            onClick={() => setIsOpen(!isDropdownOpen)}
            padding={isCollapsed ? '3.5px 10px' : '5.5px 10px'}
          >
            {isCollapsed ? <IconDots /> : null}
            {!isCollapsed && (
              <>
                <Text
                  me="auto"
                  color="black.600"
                  fontSize="14px"
                  fontWeight={600}
                  textTransform="uppercase"
                >
                  {name}
                </Text>
                <IconArrowRight transform={isDropdownOpen ? 'rotate(90deg)' : 'none'} />
              </>
            )}
          </HStack>
          {isDropdownOpen ? (
            <VStack align="flex-start" mt="12px" spacing="4px">
              {routes.map((route, index) => (
                <SidebarLink key={index} isCollapsed={isCollapsed} route={route} />
              ))}
            </VStack>
          ) : null}
        </Box>
      </>
    );
  }

  return (
    <NavLink to={showsDemoHint ? '' : defaultPath || path}>
      <HStack
        bg={isActive ? 'rgba(6, 74, 196, 0.15)' : 'transparent '}
        borderRadius="12px"
        h="40px"
        p="0 10px"
        spacing="10px"
        justify="center"
        _hover={{
          bg: 'rgba(6, 74, 196, 0.1)',
        }}
      >
        {isActive ? iconActive : icon}
        {!isCollapsed ? (
          showsDemoHint ? (
            <Popover
              returnFocusOnClose={false}
              isOpen
              placement="right"
              onClose={onDismissDemoHint}
              offset={isMobile ? [110, 40] : undefined}
            >
              <PopoverTrigger>
                <Text me="auto" color="black.600" fontWeight={600}>
                  {name}
                </Text>
              </PopoverTrigger>
              <PopoverDemoHint onClose={onDismissDemoHint} onGoToDemo={onDismissDemoHint} />
            </Popover>
          ) : (
            <SidebarText name={name} isActive={isActive} />
          )
        ) : null}
      </HStack>
    </NavLink>
  );
};

export default SidebarLink;
