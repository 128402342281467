import { TemplateColorSize } from '@/components/types';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

interface SizesProps {
  sizes: TemplateColorSize[];
  onSelectedSize: (id: string) => void;
  selectedSize: string;
}

const Sizes = ({ sizes, onSelectedSize, selectedSize }: SizesProps) => {
  return !isEmpty(sizes) ? (
    <HStack mb={{ base: '20px', md: '24px' }} mt="20px" spacing="10px">
      {sizes.map(({ id, name }) => {
        const isSelected = id === selectedSize;

        return (
          <Flex
            align="center"
            as="button"
            bg={isSelected ? 'brand.600' : 'transparent'}
            border="1px solid"
            borderColor={isSelected ? 'transparent' : 'secondaryDarkGray.600'}
            borderRadius="4px"
            onClick={() => onSelectedSize(id)}
            h="34px"
            key={id}
            justify="center"
            w="34px"
          >
            <Text
              color={isSelected ? '#FFFFFF' : 'secondaryDarkGray.600'}
              fontSize="sm"
              fontWeight={isSelected ? 500 : 400}
            >
              {name}
            </Text>
          </Flex>
        );
      })}
    </HStack>
  ) : null;
};

export default Sizes;
