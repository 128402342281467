import AuthContainerModal from '../components/AuthContainerModal';

import SignUpForm from './SignUpForm';

interface SignUpModalProps {
  onClose: () => void;
  onGoToSignIn: () => void;
  onSignedIn: () => void;
  onSignedUp?: () => void;
}

export default function SignUpModal({
  onGoToSignIn,
  onSignedIn,
  onSignedUp,
  ...rest
}: SignUpModalProps) {
  return (
    <AuthContainerModal title="Welcome to Ablo!" {...rest}>
      <SignUpForm onGoToSignIn={onGoToSignIn} onSignedIn={onSignedIn} onSignedUp={onSignedUp} />
    </AuthContainerModal>
  );
}
