import { Box, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface TrackingInfoItemProps {
  isLast: boolean;
  label: string;
  value: string | ReactNode;
}

const TrackingInfoItem = ({ label, value, isLast }: TrackingInfoItemProps) => (
  <Box
    borderBottom={isLast ? 'none' : '1px solid'}
    borderBottomColor="borderSecondary"
    pb="8px"
    w="100%"
  >
    <Text color="secondaryDarkGray.600" mb="4px" textStyle="bodySuperSmall">
      {label}
    </Text>
    <Text fontWeight={600} textStyle="bodySmall">
      {value}
    </Text>
  </Box>
);

export default TrackingInfoItem;
