import { Icon } from '@chakra-ui/react';

const IconMerchShopActive = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.52145 2.83021L1.27417 2.83022V8.40195L4.75095 8.40193V17.5325H15.3404V8.40193H18.7129V2.8302L13.4656 2.83021C13.4639 3.32316 13.377 3.81218 13.2087 4.27031C13.0388 4.73304 12.7883 5.15706 12.4692 5.51688C12.15 5.87682 11.7678 6.16599 11.3425 6.36461C10.917 6.56334 10.4584 6.66669 9.99353 6.66669C9.52864 6.66669 9.07006 6.56334 8.64457 6.36461C8.21931 6.16599 7.83707 5.87682 7.51785 5.51688C7.19874 5.15706 6.94831 4.73304 6.77833 4.27031C6.61003 3.81218 6.52315 3.32317 6.52145 2.83021Z"
      fill="#064AC4"
    />
    <path
      d="M12.4656 2.83021L7.52146 2.83021C7.52316 3.20738 7.58991 3.57953 7.717 3.92549C7.84574 4.27595 8.03316 4.5908 8.26601 4.85337C8.49877 5.11582 8.77181 5.32035 9.06774 5.45856C9.36343 5.59667 9.67781 5.66669 9.99353 5.66669C10.3092 5.66669 10.6236 5.59667 10.9193 5.45856C11.2152 5.32035 11.4883 5.11582 11.721 4.85337C11.9539 4.5908 12.1413 4.27595 12.2701 3.92549C12.3972 3.57953 12.4639 3.20738 12.4656 2.83021Z"
      fill="#064AC4"
    />
  </Icon>
);

export default IconMerchShopActive;
