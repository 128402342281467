import { Center, Image } from '@chakra-ui/react';

import { Design } from '@/lib/types';

type DesignSideImageProps = {
  sideName: string;
  design: Design;
  height?: number | string;
  width: number | string;
  imageWidth: number;
  isClickable?: boolean;
};

const DesignSideImage = ({
  design,
  sideName,
  isClickable,
  height,
  width,
}: DesignSideImageProps) => {
  const { sides = [] } = design;

  const designSide = sides.find(({ templateSide }) => templateSide.name === sideName) || {};

  const { previewImage } = designSide;

  return (
    <Center
      bg="#EAEAEA"
      borderRadius={isClickable ? 4 : 8}
      cursor={isClickable ? 'pointer' : 'auto'}
      position="relative"
      overflow="hidden"
      height={height}
      width={width}
    >
      <Image src={previewImage} width="100%" height="100%" />
    </Center>
  );
};

export default DesignSideImage;
