import { Icon } from '@chakra-ui/react';

const IconCustomArtStyle = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3553 9.99982C18.3553 14.6143 14.6145 18.3551 10 18.3551C5.3855 18.3551 1.64471 14.6143 1.64471 9.99982C1.64471 5.38532 5.3855 1.64453 10 1.64453C14.6145 1.64453 18.3553 5.38532 18.3553 9.99982ZM16.9553 9.99982C16.9553 13.8411 13.8413 16.9551 10 16.9551C6.1587 16.9551 3.04471 13.8411 3.04471 9.99982C3.04471 6.15852 6.1587 3.04453 10 3.04453C13.8413 3.04453 16.9553 6.15852 16.9553 9.99982Z"
      fill="black"
    />
    <path
      d="M9.25 10.7498V14.4442H10.75V10.7498H14.4444V9.24976H10.75V5.55542H9.25V9.24976L5.5556 9.24976V10.7498H9.25Z"
      fill="black"
    />
  </Icon>
);

export default IconCustomArtStyle;
