import Resizer from 'react-image-file-resizer';

const MAX_IMAGE_DIMENSION = 2048;

export const toBase64 = (file): Promise<ArrayBuffer | string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const downsizeImageFileToBase64 = async (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      MAX_IMAGE_DIMENSION,
      MAX_IMAGE_DIMENSION,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });
