import { useEffect, useState } from 'react';

import { usePubNub } from 'pubnub-react';

import { User } from '@/components/types';

import { useQueryClient } from '@tanstack/react-query';
import AdminDashboard from './AdminDashboard';
import OutOfCreditsModal from '@/components/subscription/OutOfCreditsModal';

const LOW_CREDITS_THRESHOLD = 10;

export default function AdminDashboardWithPubnub({ me }) {
  const [isOutOfCreditsModalVisible, setOutOfCreditsModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const pubnub = usePubNub();

  useEffect(() => {
    if (!me) {
      return;
    }

    const handleMessage = (event) => {
      const message = event.message;

      const { creditsRemaining } = message;

      if (creditsRemaining < LOW_CREDITS_THRESHOLD) {
        setOutOfCreditsModalVisible(true);
      }

      queryClient.setQueryData(['me'], (oldMe: User) => ({
        ...oldMe,
        client: {
          ...oldMe.client,
          credits: creditsRemaining,
        },
      }));
    };

    const listenerParams = { message: handleMessage };

    const channels = [`client_${me.client.id}`];

    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels });

    return () => {
      pubnub.unsubscribe({ channels });
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, me, queryClient]);

  return (
    <>
      <AdminDashboard me={me} />
      {isOutOfCreditsModalVisible ? (
        <OutOfCreditsModal onClose={() => setOutOfCreditsModalVisible(false)} />
      ) : null}
    </>
  );
}
