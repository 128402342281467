import { Icon } from '@chakra-ui/react';

const IconTeamActive = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.4574 2.61133C4.72792 2.61133 3.3259 4.01335 3.3259 5.74283C3.3259 7.47231 4.72792 8.87433 6.4574 8.87433C8.18688 8.87433 9.5889 7.47231 9.5889 5.74283C9.5889 4.01335 8.18688 2.61133 6.4574 2.61133Z"
      fill="#064AC4"
    />
    <path
      d="M13.5426 2.61133C11.8131 2.61133 10.4111 4.01335 10.4111 5.74283C10.4111 7.47231 11.8131 8.87433 13.5426 8.87433C15.2721 8.87433 16.6741 7.47231 16.6741 5.74283C16.6741 4.01335 15.2721 2.61133 13.5426 2.61133Z"
      fill="#064AC4"
    />
    <path
      d="M6.60429 10.3756C9.61368 10.5784 11.9919 13.0837 11.9919 16.1445V17.3878H0.886169V15.9449L0.886456 15.8878C0.888192 15.7149 0.897798 15.544 0.914933 15.3753C1.20024 12.5659 3.57281 10.3737 6.45741 10.3737C6.50652 10.3737 6.55548 10.3743 6.60429 10.3756Z"
      fill="#064AC4"
    />
    <path
      d="M10.7614 11.1164C12.1314 12.3574 12.9919 14.1505 12.9919 16.1445V17.3878H19.1138V15.9449C19.1138 12.868 16.6195 10.3737 13.5426 10.3737C12.5296 10.3737 11.5798 10.644 10.7614 11.1164Z"
      fill="#064AC4"
    />
  </Icon>
);

export default IconTeamActive;
