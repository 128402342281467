import { User } from '@/lib';
import { Center, HStack, Image, Text } from '@chakra-ui/react';

const AVATAR_MOBILE_SIZE = 17;
const AVATAR_DESKTOP_SIZE = 16;

interface CreatorAvatarProps {
  color?: string;
  fontSize?: number;
  opacity?: number;
  maxW?: string;
  user: User;
}

const AvatarStyles = {
  borderRadius: '50%',
  w: { base: `${AVATAR_MOBILE_SIZE}px`, md: `${AVATAR_DESKTOP_SIZE}px` },
  h: { base: `${AVATAR_MOBILE_SIZE}px`, md: `${AVATAR_DESKTOP_SIZE}px` },
};

export default function CreatorAvatar({
  color,
  fontSize,
  maxW,
  opacity,
  user,
}: CreatorAvatarProps) {
  const { avatarUrl, firstName, lastName } = user || {};

  return (
    <HStack mt="2px" spacing="6px" w="100%">
      {avatarUrl ? (
        <Image src={avatarUrl} {...AvatarStyles} />
      ) : (
        <Center bg="#929CAB" {...AvatarStyles}>
          <Text color="#FFFFFF" fontSize="10px" fontWeight={500}>
            {user ? `${firstName[0]}` : 'A'}
          </Text>
        </Center>
      )}
      <Text
        color={color}
        fontSize={`${fontSize || 13}px`}
        flex={1}
        opacity={opacity || 1}
        maxW={maxW || 'none'}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {user ? `${firstName || ''} ${lastName || ''}` : 'Anonymous'}
      </Text>
    </HStack>
  );
}
