import Button from '@/components/button';

import { H2 } from '@/components/typography/Headings';
import IconBack from '@/lib/components/icons/IconBack';
import { Box, Button as ChakraButton, HStack, Spinner, Text } from '@chakra-ui/react';
import PromptInput from '../components/PromptInput';
import { useEffect, useRef, useState } from 'react';
import { CanvasObject, Favorite } from '@/lib/types';
import { getMaskImage } from '../../utils/inpainting-mask';
import { uploadBase64ToStorage } from '@/api/storage';

import { IAbloImage, IInpaintingRequest } from '@space-runners/ablo-ts-sdk';

import ErrorMessage from '../components/ErrorMessage';
import ImagesPreview from '../components/ImagesPreview';

const BACK_BUTTON_SIZE = 40;

interface AiEditingProps {
  maskCanvases: {
    canvas: CanvasObject;
    maskLayer: CanvasObject;
  };
  onBack: () => void;
  inpaint?: (options: IInpaintingRequest) => Promise<IAbloImage[]>;
  addFavorite: (favorite: Favorite) => void;
  favorites: Favorite[];
  removeFavorite: (id: string) => void;
  onGeneratedImageSelected: (image: IAbloImage) => void;
}

const AiEditing = ({
  maskCanvases,
  onBack,
  inpaint,
  onGeneratedImageSelected,
  addFavorite,
  favorites,
  removeFavorite,
}: AiEditingProps) => {
  const subjectInputRef = useRef(null);

  const [prompt, setPrompt] = useState('');
  const [error, setError] = useState<string>(null);
  const [isGenerating, setGenerating] = useState(false);

  const [images, setImages] = useState<IAbloImage[]>([]);
  const [selectedImage, setSelectedImage] = useState<IAbloImage>(null);

  const [hasMaskChanges, setHasMaskChanges] = useState(false);
  const [isTutorialVisible, setTutorialVisible] = useState(true);

  useEffect(() => {
    const canvas = maskCanvases.maskLayer;

    canvas.on('path:created', () => {
      setHasMaskChanges(true);
    });

    return () => {
      if (canvas) {
        canvas.off('path:created');
      }
    };
  }, []);

  useEffect(() => {
    if (subjectInputRef.current) {
      subjectInputRef.current.focus();
    }
  }, []);

  const handleGenerate = async () => {
    const { canvas, maskLayer } = maskCanvases;

    setError(null);
    setImages([]);
    setGenerating(true);

    setHasMaskChanges(false);

    const { src } = canvas._objects.find(({ type }) => type === 'image');

    let imageUrl = src;

    if (src.startsWith('data:image/png;base64')) {
      imageUrl = await uploadBase64ToStorage(src, 'image/png');
    }

    const maskDataURL = getMaskImage(maskLayer);

    try {
      const maskUrl = await uploadBase64ToStorage(maskDataURL, 'image/png');

      const images = await inpaint({
        imageUrl,
        maskUrl,
        numPixelsToGrowMask: 5,
        prompt,
        numSamples: 3,
      });

      setImages(images);
      setSelectedImage(images[0]);
    } catch (err) {
      let message = err?.response?.data?.message;

      if (message && (message.includes('Bad words') || message.includes('Invalid prompts'))) {
        message = 'Inappropriate prompt. Please type again';
      }

      setError(message);
    } finally {
      setGenerating(false);
    }
  };

  const clearCanvases = () => {
    const { canvas, maskLayer } = maskCanvases;

    canvas.clear();
    maskLayer.clear();
  };

  const handleBack = () => {
    clearCanvases();

    onBack();
  };

  const handlePlaceArtwork = () => {
    clearCanvases();

    onGeneratedImageSelected(selectedImage);
  };

  const hasResults = images.length > 0;

  return (
    <Box p={{ base: '30px 20px', md: '23px 32px' }}>
      <HStack mb="11px" spacing="17px">
        <Button
          aria-label="back"
          icon={<IconBack h="20px" w="20px" />}
          h={`${BACK_BUTTON_SIZE}px`}
          minW="auto"
          padding="10px"
          w={`${BACK_BUTTON_SIZE}px`}
          onClick={handleBack}
          secondary
        >
          <IconBack />
        </Button>
        <H2>Edit Image</H2>
      </HStack>
      {hasResults ? (
        <ImagesPreview
          images={images}
          selectedImage={selectedImage}
          onSelectedImage={setSelectedImage}
          onPlaceArtwork={handlePlaceArtwork}
          addFavorite={addFavorite}
          favorites={favorites}
          removeFavorite={removeFavorite}
          showGenerateNewButtons={false}
        />
      ) : null}
      {hasResults ? null : (
        <Text mb="10px" textStyle="body">
          Select an area on your image and enter your prompt below to easily edit or add elements to
          that specific region.
        </Text>
      )}
      <Box mb={{ base: 0, md: '10px' }} />
      <PromptInput
        onChange={(e) => setPrompt(e.target.value)}
        ref={subjectInputRef}
        value={prompt}
        placeholder="Enter your prompt"
      />
      {error ? <ErrorMessage error={error} /> : null}
      <Button
        mb="28px"
        mt={{ base: 0, md: '10px' }}
        isDisabled={!prompt || isGenerating}
        onClick={() => handleGenerate()}
        secondary={hasResults && !hasMaskChanges}
        w="100%"
      >
        {isGenerating ? <Spinner /> : hasResults && !hasMaskChanges ? 'Regenerate' : 'Generate'}
      </Button>
      <ChakraButton
        colorScheme="secondaryDarkGray"
        fontSize={{ base: 'sm', md: 'md' }}
        size="sm"
        mb="9px"
        onClick={() => setTutorialVisible(!isTutorialVisible)}
        variant="link"
      >
        Tutorial
        <IconBack color="#626F82" transform={`rotate(${isTutorialVisible ? 90 : -90}deg)`} />
      </ChakraButton>
      {isTutorialVisible ? (
        <video
          autoPlay
          style={{
            borderRadius: '12px',
            width: '100%',
            objectFit: 'cover',
          }}
          loop
          muted
          playsInline
          src="/ABLO_InpaintTutorial_mobile.mp4"
        />
      ) : null}
    </Box>
  );
};

export default AiEditing;
