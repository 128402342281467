import { Icon } from '@chakra-ui/react';

const IconLargeMobile = ({ color }) => (
  <Icon
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="14" height="6.125" fill={color || '#D3DEEA'} />
    <rect y="7.875" width="14" height="6.125" fill={color || '#D3DEEA'} />
  </Icon>
);

export default IconLargeMobile;
