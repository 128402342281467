import { Box, HStack, VStack } from '@chakra-ui/react';

import EmptyState from '@/components/empty-state';
import { Template } from '@/components/types';
import { Design } from '@/lib/types';

import MyDesignCard from './MyDesignCard';
import { useCallback, useEffect, useRef, useState } from 'react';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

const ITEMS_PER_PAGE = 8;

const resolveAfter = (delay) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(''), delay);
  });

type DesignsListProps = {
  designs: Design[];
  onDesignDeleted: (id: string) => void;
  onDesignUpdated: (updates: Partial<Design>) => void;
  templates: Template[];
};

const DesignsList = ({
  designs: allDesigns,
  onDesignUpdated,
  onDesignDeleted,
}: DesignsListProps) => {
  const [isLoadingNextPage, setLoadingNextPage] = useState(true);

  const [designs, setDesigns] = useState<Design[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const observer = useRef<IntersectionObserver>(null);

  useEffect(() => {
    setCurrentPage(1);
  }, [allDesigns]);

  useEffect(() => {
    const loadNextItems = async () => {
      setLoadingNextPage(true);

      const offset = (currentPage - 1) * ITEMS_PER_PAGE;

      const newDesigns = allDesigns.slice(offset, offset + ITEMS_PER_PAGE);

      await resolveAfter(1000);

      setLoadingNextPage(false);

      setDesigns(currentPage === 1 ? newDesigns : (oldDesigns) => [...oldDesigns, ...newDesigns]);
    };

    loadNextItems();
  }, [currentPage, allDesigns]);

  const lastElementRef = useCallback(
    (node) => {
      const hasAllItems = designs.length === allDesigns.length;

      if (isLoadingNextPage || hasAllItems) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoadingNextPage]
  );

  if (!isLoadingNextPage && !designs.length) {
    return <EmptyState onAdd={() => {}} message="No designs found" />;
  }

  return (
    <VStack>
      <HStack alignItems="flex-start" mb="20px" spacing="16px" wrap="wrap">
        {designs.map((design, index) => (
          <Box
            key={design.id + index}
            ref={index === designs.length - 1 ? lastElementRef : null}
            w={{ base: 'calc(50% - 8px)', '2sm': '184px', lg: '185px' }}
          >
            <MyDesignCard
              key={design.id}
              design={design}
              onDesignDeleted={onDesignDeleted}
              onDesignUpdated={onDesignUpdated}
            />
          </Box>
        ))}
      </HStack>
      {isLoadingNextPage ? <LoadingSpinner /> : null}
    </VStack>
  );
};

export default DesignsList;
