import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  HStack,
  TableColumnHeaderProps,
  TableCellProps,
} from '@chakra-ui/react';
import Panel from '@/components/panel';
import { FC } from 'react';
import { useTemplateSizeChart } from '@/api/templates';
import _ from 'lodash';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

export type SizeTable = {
  description: string;
  measurements: {
    type_label: string;
    values: {
      size: string;
      value: string;
    }[];
  }[];
  unit: string;
};

type SizeChartProps = {
  templateId: string;
};

const thStyle: TableColumnHeaderProps = {
  borderBottom: '1px solid',
  borderColor: 'borderSecondary',
  color: 'inherit',
  fontFamily: 'inherit',
  fontSize: 'sm',
  fontWeight: 400,
  letterSpacing: 'inherit',
  padding: '20px 20px 20px 0',
  textTransform: 'none',
};

const tdStyle: TableCellProps = {
  border: 'none',
  fontSize: 'sm',
  padding: '12px 6px 0px 0',
};

export const SizeChart: FC<SizeChartProps> = ({ templateId }) => {
  const { data: sizeChart, isLoading } = useTemplateSizeChart(templateId);

  const sizeTables: SizeTable[] =
    (sizeChart?.size_tables || []).filter((sizeTable) => sizeTable.type === 'product_measure') ||
    [];

  // If there is only one size table and it is in inches, add a centimeters size table
  if (sizeTables.length === 1 && sizeTables[0].unit.toLowerCase() === 'inches') {
    sizeTables.push({
      description: sizeTables[0].description.replaceAll('1"', '2.5cm'),
      measurements: sizeTables[0].measurements.map((measurement) => ({
        type_label: measurement.type_label,
        values: measurement.values.map((value) => ({
          size: value.size,
          value: Math.round(parseFloat(value.value) * 2.54).toString(),
        })),
      })),
      unit: 'Centimeters',
    });
  }

  return (
    <Panel title="Size Chart">
      {isLoading ? (
        <HStack justifyContent="space-evenly">
          <LoadingSpinner thickness="4px" size="xl" />
        </HStack>
      ) : (
        <Tabs p={{ base: '0 12px', md: '0 16px' }}>
          <TabList
            borderBottom="1px solid"
            borderBottomColor="borderSecondary"
            gap={0}
            mb={0}
            w="100%"
          >
            {sizeTables.map((sizeTable) => (
              <Tab
                key={sizeTable.unit}
                color="black.600"
                fontSize="sm"
                p="0 24px 22px 0"
                mr="24px"
                _selected={{ fontWeight: 'bold' }}
                sx={{ fontWeight: 'normal' }}
              >
                {_.capitalize(sizeTable.unit)}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {sizeTables.map((sizeTable, index) => {
              return (
                <TabPanel key={index} p={0}>
                  <TableContainer>
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th key={0} {...thStyle}>
                            Size Label
                          </Th>
                          {sizeTable.measurements.map((measurement, index) => {
                            return (
                              <Th key={index + 1} {...thStyle}>
                                {measurement.type_label}
                              </Th>
                            );
                          })}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {sizeTable.measurements[0].values.map((value, valueIndex) => (
                          <Tr key={value.size}>
                            <Td key={0} {...tdStyle}>
                              {value.size}
                            </Td>
                            {sizeTable.measurements.map((measurement, index) => {
                              return (
                                <Td key={index + 1} {...tdStyle}>
                                  {measurement.values[valueIndex].value}
                                </Td>
                              );
                            })}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Box
                    dangerouslySetInnerHTML={{ __html: sizeTable.description }}
                    textStyle="overline"
                    color="secondaryDarkGray.600"
                    mt="20px"
                    mb="16px"
                  />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      )}
    </Panel>
  );
};
