import { Icon } from '@chakra-ui/react';

const IconDesignCenterActive = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 9V2H9V9H2Z" fill="#064AC4" />
    <path d="M11 9V2H18V9H11Z" fill="#064AC4" />
    <path d="M9 18V11H2V18H9Z" fill="#064AC4" />
    <path d="M11 18V11H18V18H11Z" fill="#064AC4" />
  </Icon>
);

export default IconDesignCenterActive;
