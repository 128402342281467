import { Center, Image, Text, VStack } from '@chakra-ui/react';
import Button from '@/components/button';
import { useHistory } from 'react-router-dom';
import EmptyCartIcon from './icons/IconEmptyChart.png';

export default function CartEmpty() {
  const history = useHistory();

  return (
    <Center h="100%" w="100%">
      <VStack spacing={0}>
        <Image mb="15px" src={EmptyCartIcon} w={100} />
        <Text mb="4px" textStyle="button">
          Shopping cart is empty
        </Text>
        <Text color="secondaryDarkGray.600" mb="21px" textStyle="caption">
          Looks like you haven’t made your choice yet
        </Text>
        <Button secondary small onClick={() => history.push('/products')}>
          Go shopping
        </Button>
      </VStack>
    </Center>
  );
}
