import { useEffect, useState } from 'react';
import { Box, Center, HStack, VStack } from '@chakra-ui/react';

import { Design } from '@/lib/types';
import DesignPreview from '@/views/demo/pages/design/components/DesignSideImage';
import Button from '@/components/button';
import { size } from 'lodash';
import IconArrowRight from '@/lib/components/icons/IconArrowRight';

const ARROW_SIZE = 16;
const ARROW_BUTTON_SIZE = 40;

interface OrderDesignPreviewGalleryProps {
  design: Design;
}

const OrderDesignPreviewGallery = ({ design }: OrderDesignPreviewGalleryProps) => {
  const [selectedSideIndex, setSelectedSideIndex] = useState<number>(0);

  const { template } = design;

  const isLeftDisabled = selectedSideIndex === 0;
  const isRightDisabled = selectedSideIndex === size(template.sides) - 1;

  useEffect(() => {
    if (!template) {
      return;
    }
    setSelectedSideIndex(0);
  }, [template]);

  return (
    template?.sides?.length && (
      <HStack
        align="flex-start"
        bg="#FFFFFF"
        borderRadius="10px"
        justify={{ base: 'space-between', md: 'flex-start' }}
        h="100%"
        spacing="5px"
        w={{ base: '100%', md: 'auto' }}
      >
        <Box position="relative">
          <DesignPreview
            design={design}
            sideName={template.sides[selectedSideIndex].name}
            imageWidth={236}
            height={236}
            width={236}
          />
          {template.sides.length > 1 && (
            <>
              <Button
                aria-label="back"
                isDisabled={isLeftDisabled}
                h={`${ARROW_BUTTON_SIZE}px`}
                w={`${ARROW_BUTTON_SIZE}px`}
                onClick={() => setSelectedSideIndex(selectedSideIndex - 1)}
                position="absolute"
                top={`calc(50% - ${ARROW_BUTTON_SIZE / 2}px)`}
                left="4px"
                secondary
              >
                <IconArrowRight
                  fill={isLeftDisabled && '#CBD6E3'}
                  transform="rotate(180deg)"
                  h={`${ARROW_SIZE}px`}
                  w={`${ARROW_SIZE}px`}
                />
              </Button>
              <Button
                aria-label="next"
                isDisabled={isRightDisabled}
                h={`${ARROW_BUTTON_SIZE}px`}
                w={`${ARROW_BUTTON_SIZE}px`}
                onClick={() => setSelectedSideIndex(selectedSideIndex + 1)}
                position="absolute"
                top={`calc(50% - ${ARROW_BUTTON_SIZE / 2}px)`}
                right="8px"
                secondary
              >
                <IconArrowRight
                  fill={isRightDisabled && '#CBD6E3'}
                  h={`${ARROW_SIZE}px`}
                  w={`${ARROW_SIZE}px`}
                />
              </Button>
            </>
          )}
        </Box>

        {template.sides.length > 1 ? (
          <VStack spacing={{ base: '4px' }}>
            {template.sides.map((side, index) => (
              <Center
                as="button"
                borderRadius={4}
                borderColor="secondaryDarkGray.600"
                borderWidth={selectedSideIndex === index ? '1px' : 0}
                key={side.name}
                onClick={() => setSelectedSideIndex(index)}
              >
                <DesignPreview
                  design={design}
                  sideName={side.name}
                  imageWidth={56}
                  isClickable
                  height="56px"
                  width="56px"
                />
              </Center>
            ))}
          </VStack>
        ) : (
          <Box w="64px" />
        )}
      </HStack>
    )
  );
};

export default OrderDesignPreviewGallery;
