import { Design } from '@/lib';
import { Center, CenterProps, Image } from '@chakra-ui/react';

interface DesignPreviewProps extends CenterProps {
  isClickable?: boolean;
  sideName: string;
  design: Design;
  height?: number | string;
  width: number | string;
  imageWidth: string;
}

const SubmitDesignSideImage = ({
  isClickable,
  design,
  sideName,
  imageWidth,
  height,
  width,
  ...rest
}: DesignPreviewProps) => {
  const { sides = [] } = design;

  const designSide = sides.find(({ templateSide }) => templateSide.name === sideName) || {};

  const { previewImage } = designSide;

  return (
    <Center
      bg="secondaryGray.300"
      _hover={
        isClickable && {
          bg: '#D3DAE6',
        }
      }
      borderRadius={4}
      cursor={isClickable && 'pointer'}
      position="relative"
      height={height}
      width={width}
      {...rest}
    >
      <Image src={previewImage} width={imageWidth} />
    </Center>
  );
};

export default SubmitDesignSideImage;
