import { Icon } from '@chakra-ui/react';

const IconLinkArrowRight = ({ color = '' }) => (
  <Icon
    width="16px"
    height="17px"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83879 3.1173L5.76808 3.04659L5.69737 3.1173L4.84937 3.9653L4.77866 4.03601L4.84937 4.10672L9.24266 8.50001L4.84937 12.8933L4.77866 12.964L4.84937 13.0347L5.69737 13.8827L5.76808 13.9534L5.83879 13.8827L11.1508 8.57072L11.2215 8.50001L11.1508 8.4293L5.83879 3.1173Z"
      fill={color || '#1C8D02'}
      stroke={color || '#1C8D02'}
      strokeWidth="0.2"
    />
  </Icon>
);

export default IconLinkArrowRight;
