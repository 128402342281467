import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { CartItem } from '@/components/types';
import OrderDesignPreviewGallery from '../OrderDesignPreviewGallery';
import { Design } from '@/lib/types';
import Button from '@/components/button';
import { useHistory } from 'react-router-dom';

interface OrderProductInfoProps {
  design: Design;
  item: CartItem;
}

const OrderProductInfo = ({ design, item }: OrderProductInfoProps) => {
  const history = useHistory();

  return (
    <Box key={item.id}>
      <HStack align="start" spacing="32px">
        <Box h="236px" w="296px">
          <OrderDesignPreviewGallery design={design} />
        </Box>
        <VStack>
          <VStack rowGap="6px" align="start">
            <Text textStyle="subtitle" as="b">
              {design.template.name} | {design.name}
            </Text>
            <Text color="secondaryDarkGray.600">
              Size: {item.templateColorSize?.size?.name || '-'} | Quantity: {item.quantity}
            </Text>
            {item.price && (
              <Text textStyle="subtitle" as="b">
                ${Number(item.price).toFixed(2)}
              </Text>
            )}
            <Button onClick={() => history.push(`/products/${design.id}`)} secondary>
              Product details
            </Button>
          </VStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default OrderProductInfo;
