import { Icon } from '@chakra-ui/react';

const IconDashboardActive = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.00391 10.092H5.6566V17.5H1.00391V10.092Z" fill="#064AC4" />
    <path d="M7.67353 0.5H12.3262V17.4999H7.67353V0.5Z" fill="#064AC4" />
    <path d="M14.3432 5.16253H18.9958V17.5H14.3432V5.16253Z" fill="#064AC4" />
  </Icon>
);

export default IconDashboardActive;
