import { Option } from '@/components/types';
import { Button, HStack } from '@chakra-ui/react';

interface CategoryTabsProps {
  tabs: Option<string>[];
  selectedValue: string;
  onSelectedValue: (value: string) => void;
}

const CategoryTabs = ({ tabs, selectedValue, onSelectedValue }: CategoryTabsProps) => {
  return (
    <HStack gap={{ base: '4px', md: '16px' }}>
      {tabs.map((tab, index) => {
        const { name, id } = tab;

        const isSelected = id === selectedValue;

        return (
          <Button
            borderRadius="6px"
            border="1px solid"
            borderColor="transparent"
            bg={isSelected ? '#F0F5FF' : 'transparent'}
            color={isSelected ? 'brand.600' : 'black.500'}
            fontSize={{ base: '14px', md: '18px' }}
            fontWeight={isSelected ? 600 : 500}
            key={index}
            lineHeight="24px"
            px={{ base: '8px', md: '16px' }}
            onClick={() => onSelectedValue(id)}
            p="9px 16px"
            _hover={{
              borderColor: 'borderLight',
            }}
            _focus={{
              bg: '#F0F5FF',
              color: 'brand.600',
            }}
          >
            {name}
          </Button>
        );
      })}
    </HStack>
  );
};

export default CategoryTabs;
