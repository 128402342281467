import { Icon } from '@chakra-ui/react';

const IconGenerateArtActive = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.52971 3.02633L8.14393 3.81574L5.52971 4.60515L4.7403 7.21937L3.95089 4.60515L1.33667 3.81574L3.95089 3.02633L4.7403 0.412109L5.52971 3.02633Z"
      fill="#064AC4"
    />
    <path
      d="M14.981 1.53699L16.2658 4.7947L19.5235 6.07953L16.2658 7.36435L14.981 10.6221L13.6961 7.36435L10.4384 6.07953L13.6961 4.7947L14.981 1.53699Z"
      fill="#064AC4"
    />
    <path
      d="M8.38568 4.94165L10.5152 10.3412L15.9147 12.4707L10.5152 14.6002L8.38568 19.9998L6.25614 14.6002L0.856628 12.4707L6.25614 10.3412L8.38568 4.94165Z"
      fill="#064AC4"
    />
  </Icon>
);

export default IconGenerateArtActive;
