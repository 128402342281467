import React, { useEffect, useState } from 'react';

import {
  Button,
  Box,
  Flex,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Show,
} from '@chakra-ui/react';

import ROUTES from '@/layouts/admin/routes';

import IconToggle from './IconToggle';
import IconDrawerMenu from '../icons/IconDrawerMenu';
import CreditsPill from './CreditsPill';
import SidebarLink from './SidebarLink';
import Separator from './components/Separator';

const HAS_SEEN_DEMO_HINT = 'HAS_SEEN_DEMO_HINT';

type SidebarContentProps = {
  isCollapsed?: boolean;
  onChangeCollapsed?: (isCollapsed: boolean) => void;
};

const SidebarContent = ({ isCollapsed, onChangeCollapsed }: SidebarContentProps) => {
  const [isDemoHintVisible, setDemoHintVisible] = useState(false);

  const handleDismissDemoHint = () => {
    localStorage.setItem(HAS_SEEN_DEMO_HINT, 'true');

    setDemoHintVisible(false);
  };

  useEffect(() => {
    if (!localStorage.getItem(HAS_SEEN_DEMO_HINT)) {
      setDemoHintVisible(true);
    }
  }, []);

  return (
    <Flex borderRight="1px solid #E2E8F0" direction="column" height="100%" pt="20px">
      <Flex
        justify="space-between"
        pl="20px"
        pb={{ base: '15px', md: 0 }}
        pr={isCollapsed ? '20px' : '15px'}
      >
        {!isCollapsed ? <CreditsPill /> : null}
        <Show above="lg">
          <Button bg="transparent" onClick={() => onChangeCollapsed(!isCollapsed)} padding={0}>
            <IconToggle isRotated={isCollapsed} />
          </Button>
        </Show>
      </Flex>
      <Separator px="20px" />
      <VStack align="flex-start" px="20px" spacing="4px">
        {ROUTES.filter(({ notInMenu }) => !notInMenu).map((route, index) => (
          <SidebarLink
            key={index}
            isCollapsed={isCollapsed}
            route={route}
            isDemoHintVisible={isDemoHintVisible}
            onDismissDemoHint={handleDismissDemoHint}
          />
        ))}
        <Separator />
      </VStack>
    </Flex>
  );
};

type SidebarProps = {
  isCollapsed?: boolean;
  onChangeCollapsed?: (isCollapsed: boolean) => void;
};

const Sidebar = ({ isCollapsed, onChangeCollapsed }: SidebarProps) => {
  return (
    <Box
      bg="#FFFFFF"
      transition="0.2s linear"
      w={`${isCollapsed ? 83 : 247}px`}
      h="calc(100vh - 80px)"
      overflowX="hidden"
      boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      display={{ sm: 'none', lg: 'block' }}
    >
      <SidebarContent isCollapsed={isCollapsed} onChangeCollapsed={onChangeCollapsed} />
    </Box>
  );
};

export const SidebarResponsive = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Flex display={{ sm: 'flex', lg: 'none' }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <IconDrawerMenu />
      </Flex>
      <Drawer isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef} placement="left">
        <DrawerOverlay />
        <DrawerContent w="285px" maxW="285px" bg="#FFFFFF">
          <DrawerCloseButton
            zIndex="3"
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <SidebarContent />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Sidebar;
