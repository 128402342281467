import { Heading, HeadingProps } from '@chakra-ui/react';

export const H1 = (props: HeadingProps) => (
  <Heading
    fontSize={{ base: '30px', md: '34px' }}
    lineHeight={{ base: '36px', md: '40px' }}
    {...props}
  />
);

export const H2 = (props: HeadingProps) => (
  <Heading
    fontSize={{ base: '24px', md: '30px' }}
    lineHeight={{ base: '32px', md: '36px' }}
    {...props}
  />
);

export const H3 = (props: HeadingProps) => (
  <Heading
    fontSize={{ base: '20px', md: '24px' }}
    lineHeight={{ base: '24px', md: '32px' }}
    {...props}
  />
);

export const H4 = (props: HeadingProps) => (
  <Heading fontSize={{ base: '17px', md: '22px' }} lineHeight="24px" {...props} />
);

export const H5 = (props: HeadingProps) => (
  <Heading
    fontSize={{ base: '14px', md: '17px' }}
    lineHeight={{ base: '18px', md: '22px' }}
    {...props}
  />
);

export const H6 = (props: HeadingProps) => (
  <Heading
    fontSize={{ base: '12px', md: '15px' }}
    lineHeight={{ base: '16px', md: '18px' }}
    {...props}
  />
);
