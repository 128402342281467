import { Icon } from '@chakra-ui/react';
import { globalStyles } from '../../theme/styles';

export const IconCheck = ({ color = null, ...rest }) => (
  <Icon
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14.4955 23.1812L23.3343 11.2167L21.3454 9.7474L14.3031 19.2802L9.89158 14.3981L8.05693 16.056L14.4955 23.1812Z"
      fill={color || globalStyles.colors.green[600]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.4C8.48895 2.4 2.40002 8.48893 2.40002 16C2.40002 23.5111 8.48895 29.6 16 29.6C23.5111 29.6 29.6 23.5111 29.6 16C29.6 8.48893 23.5111 2.4 16 2.4ZM4.87275 16C4.87275 9.85458 9.8546 4.87273 16 4.87273C22.1454 4.87273 27.1273 9.85458 27.1273 16C27.1273 22.1454 22.1454 27.1273 16 27.1273C9.8546 27.1273 4.87275 22.1454 4.87275 16Z"
      fill={color || globalStyles.colors.green[600]}
    />
  </Icon>
);
