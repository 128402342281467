const IconWarning = ({ color = '' }) => (
  <svg
    width="20px"
    height="21px"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 18.8333C5.39734 18.8333 1.6665 15.1025 1.6665 10.5C1.6665 5.89751 5.39734 2.16667 9.99984 2.16667C14.6023 2.16667 18.3332 5.89751 18.3332 10.5C18.3332 15.1025 14.6023 18.8333 9.99984 18.8333ZM9.1665 13V14.6667H10.8332V13H9.1665ZM9.1665 6.33334V11.3333H10.8332V6.33334H9.1665Z"
      fill={color}
    />
  </svg>
);

export default IconWarning;
