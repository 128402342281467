import { Center, Image } from '@chakra-ui/react';

type DesignMockupImageProps = {
  url: string;
  height?: number | string;
  width: number | string;
  imageWidth: number;
  isClickable?: boolean;
};

const DesignMockupImage = ({ url, isClickable, height, width }: DesignMockupImageProps) => {
  return (
    <Center
      bg="#EAEAEA"
      borderRadius={isClickable ? 4 : 8}
      cursor={isClickable ? 'pointer' : 'auto'}
      position="relative"
      overflow="hidden"
      height={height}
      width={width}
    >
      <Image src={url} width="100%" height="100%" />
    </Center>
  );
};

export default DesignMockupImage;
