import { Icon } from '@chakra-ui/react';

const IconOrdersActive = () => (
  <Icon
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.21247 5.91262L12.7421 1.73682L17.3028 3.52613L6.92968 7.82047L2.21247 5.91262ZM2.20752 7.20604L2.20752 18.575L12.0935 22.6676L12.0935 11.2043L2.20752 7.20604ZM13.2944 22.7099L23.3908 18.575L23.3908 7.299L13.2944 11.1808L13.2944 22.7099ZM23.3908 6.01239V5.91459L18.9148 4.15855L8.51778 8.46277L12.6601 10.1381L23.3908 6.01239Z"
      fill="#064AC4"
    />
  </Icon>
);

export default IconOrdersActive;
