import { Icon } from '@chakra-ui/react';

const IconDesignCenter = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 3.5H3.5V7.5H7.5V3.5ZM2 2V9H9V2H2Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 3.5H12.5V7.5H16.5V3.5ZM11 2V9H18V2H11Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 12.5H3.5V16.5H7.5V12.5ZM2 11V18H9V11H2Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 12.5H12.5V16.5H16.5V12.5ZM11 11V18H18V11H11Z"
      fill="black"
    />
  </Icon>
);

export default IconDesignCenter;
