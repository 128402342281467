import {
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import IconChevronDown from '@/components/icons/IconChevronDown';
import { Option } from '@/components/types';

const ButtonLink = (props) => <Button size="sm" fontWeight={400} variant="link" {...props} />;

const IconCheckmark = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.55001 18L3.85001 12.3L5.27501 10.875L9.55001 15.15L18.725 5.97498L20.15 7.39998L9.55001 18Z"
      fill="#064AC4"
    />
  </Icon>
);

type MultiselectProps<T> = {
  customOptionRenderer?: (name: string, id: T) => React.ReactNode;
  name: string;
  onUpdateSelectedValues: (values: T[]) => void;
  options: Option<T>[];
  selectedValues: T[];
};

const Multiselect = function <T>({
  customOptionRenderer,
  options,
  selectedValues = [],
  onUpdateSelectedValues,
  name,
}: MultiselectProps<T>) {
  const toggleSelected = (value) => {
    let newSelected;

    if (selectedValues.includes(value)) {
      newSelected = selectedValues.filter((s) => s !== value);
    } else {
      newSelected = [...selectedValues, value];
    }

    onUpdateSelectedValues(newSelected);
  };

  const handleSelectAll = () => {
    const newSelected = options.map(({ id }) => id);

    onUpdateSelectedValues(newSelected);
  };

  const handleClear = () => {
    onUpdateSelectedValues([]);
  };

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        bg="transparent"
        border="1px solid"
        borderColor="gray.400"
        borderRadius="7px"
        p="10px 3px 10px 16px"
      >
        <HStack spacing={0}>
          <Text color="secondaryDarkGray.500" fontSize="sm">
            {name}
          </Text>
          <IconChevronDown />
        </HStack>
      </MenuButton>
      <MenuList p="0px" mt="10px" borderRadius="20px" bg="#FFFFFF" border="none">
        <HStack p="14px">
          <ButtonLink onClick={handleSelectAll}>Select All</ButtonLink>
          <ButtonLink onClick={handleClear}>Clear</ButtonLink>
        </HStack>
        {options.map(({ id, name }, index) => {
          const isSelected = selectedValues.includes(id);

          return (
            <MenuItem
              key={index}
              onClick={() => toggleSelected(id)}
              _hover={{ bg: 'secondaryGray.300' }}
              _focus={{ bg: 'secondaryGray.300' }}
              px="14px"
            >
              <Flex justify="space-between" w="100%">
                {customOptionRenderer ? customOptionRenderer(name, id) : name}
                {isSelected ? <IconCheckmark /> : null}
              </Flex>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default Multiselect;
