import {
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { logout, useMe } from '@/api/auth';

import { SidebarResponsive } from '@/components/sidebar';

import { trackEvent } from '../../analytics';
import { Event } from '../../analytics/events';
import IconPerson from '../icons/IconPerson';
import CartIndicator from './CartIndicator';

const MenuItemStyles = {
  _hover: { bg: 'gray.50' },
  _focus: { bg: 'gray.50' },
  color: 'black.600',
  borderRadius: '8px',
};

type NavbarProps = {
  rightSideContent?: React.ReactNode;
};

export default function Navbar(props: NavbarProps) {
  const { rightSideContent } = props;

  const { data: me } = useMe();

  const history = useHistory();

  const handleGoToProfileSettings = () => history.push('/profile');

  const handleGoToTermsOfUse = () => history.push('/terms');

  const handleGoToPrivacyPolicy = () => history.push('/privacy-policy');

  const cartItemsCount = me ? me.cart.items.reduce((result, item) => result + item.quantity, 0) : 0;

  return (
    <Flex
      bg="#FFFFFF"
      borderBottom="1px solid"
      borderColor="gray.200"
      id="ablo-dashboard-navbar"
      p={{ base: '30px 13px 16px 20px', md: '20px 32px 20px 4px' }}
      alignItems="center"
      position="relative"
      h={{ base: '70px', md: '80px' }}
      justifyContent="space-between"
      w="100%"
    >
      <SidebarResponsive />
      <Button
        _hover={{ bg: 'transparent' }}
        onClick={() => history.push('/')}
        p={0}
        variant="ghost"
      >
        <Image
          src="/AbloLogoBlack.png"
          alt="Ablo"
          width={{ base: '41px', md: '49px' }}
          position="relative"
          left={{ base: cartItemsCount ? '25px' : 0, md: 0 }}
        ></Image>
      </Button>
      <HStack w={{ base: cartItemsCount ? '75px' : '25px', md: 'auto' }}>
        {rightSideContent}
        {cartItemsCount ? <CartIndicator cartItemsCount={cartItemsCount} /> : null}
        <Menu
          onOpen={() => trackEvent(Event.AVATAR_MENU_OPENED)}
          onClose={() => trackEvent(Event.AVATAR_MENU_CLOSED)}
        >
          <MenuButton ml={{ base: 0, md: '9px' }} p="0px">
            <IconPerson />
          </MenuButton>
          <MenuList p="0px" mt="10px" borderRadius="20px" bg="#FFFFFF" border="none">
            <Flex w="100%" mb="0px">
              {me ? (
                <Text
                  ps="20px"
                  pt="16px"
                  pb="10px"
                  w="100%"
                  borderBottom="1px solid"
                  borderColor="#E6ECFA"
                  fontSize="sm"
                  fontWeight="700"
                >
                  👋&nbsp; Hey, {me.firstName}
                </Text>
              ) : null}
            </Flex>
            <Flex flexDirection="column" p="10px">
              <MenuItem {...MenuItemStyles} onClick={handleGoToProfileSettings}>
                <Text fontSize="sm">Edit profile</Text>
              </MenuItem>
              <MenuItem {...MenuItemStyles} onClick={handleGoToTermsOfUse}>
                <Text fontSize="sm">Terms of service</Text>
              </MenuItem>
              <MenuItem {...MenuItemStyles} onClick={handleGoToPrivacyPolicy}>
                <Text fontSize="sm">Privacy Policy</Text>
              </MenuItem>
              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                color="red.400"
                borderRadius="8px"
                onClick={logout}
                px="14px"
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );
}
