import { Box, Button, Center, Flex, Hide, useBreakpointValue } from '@chakra-ui/react';

import ButtonCTA from '@/lib/components/Button';
import { Template } from '@/lib/types';

import HintPopover from '../components/hints/HintPopover';
import { getCanvasHintProps, Hint } from '../components/hints/hints';
import IconArrowRight from '@/lib/components/icons/IconArrowRight';
import IconArrowRightSmall from '@/lib/components/icons/IconArrowRightSmall';

type Props = {
  activeHint: Hint;
  isQuickSaving?: boolean;
  onNextHint: (hint: Hint) => void;
  onBack?: () => void;
  onNext?: () => void;
  selectedSide: string;
  onSelectedSide: (side: string) => void;
  template: Template;
};

export default function Navbar(props: Props) {
  const {
    onBack,
    onNext,
    isQuickSaving,
    selectedSide,
    onSelectedSide,
    template,
    activeHint,
    onNextHint,
  } = props;

  const { sides } = template;

  const shouldHideSidePicker = !sides || sides.length === 1;

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Flex
      align="center"
      bg="#FFFFFF"
      borderBottom="1px solid #E7E7E7"
      height={!isMobile && shouldHideSidePicker ? 0 : '63px'}
      justify="space-between"
      padding={0}
      pl={onBack ? 0 : '20px'}
      pr="16px"
      overflow="auto"
    >
      <Hide above="lg">
        {onBack ? (
          <Button variant="ghost" bg="transparent" onClick={onBack}>
            <IconArrowRight transform="rotate(180deg)" />
          </Button>
        ) : null}
      </Hide>
      <HintPopover
        isOpen={activeHint === Hint.SIDE_PICKER}
        {...getCanvasHintProps(Hint.SIDE_PICKER)}
        onNext={() => onNextHint(Hint.CUSTOM_QR_CODE)}
        onClose={() => onNextHint(null)}
      >
        {shouldHideSidePicker ? (
          <Box />
        ) : (
          <Flex
            borderRadius={9}
            justifyContent={{ base: 'center', lg: 'flex-start' }}
            flex={1}
            p={{ base: '6px 0', lg: '6px 20px' }}
            {...(activeHint === Hint.SIDE_PICKER
              ? { bg: '#FFFFFF', flex: 0, px: '26px', zIndex: 4 }
              : {})}
          >
            {sides.map((side) => {
              const isSelected = selectedSide === side.name;

              return (
                <Button
                  bg={isSelected ? '#CBD5E0' : 'transparent'}
                  borderRadius={40}
                  color="#090909"
                  fontSize="12px"
                  fontWeight={isSelected ? 600 : 400}
                  key={side.id}
                  onClick={() => onSelectedSide(side.name)}
                  p="8px 16px"
                >
                  {side.name}
                </Button>
              );
            })}
          </Flex>
        )}
      </HintPopover>
      <Hide above="lg">
        {onNext ? (
          <HintPopover
            isOpen={activeHint === Hint.FINISH_AND_SHARE}
            {...getCanvasHintProps(Hint.FINISH_AND_SHARE)}
            offset={[0, 20]}
            onNext={() => onNextHint(null)}
            onClose={() => onNextHint(null)}
          >
            <Center
              {...(activeHint === Hint.FINISH_AND_SHARE
                ? {
                    bg: '#FFFFFF',
                    borderRadius: '12px',
                    h: '42px',
                    pl: '12px',
                    zIndex: 4,
                  }
                : {})}
            >
              <ButtonCTA
                color="#000000"
                fontSize="12px"
                h={{ base: '32px', md: '38px' }}
                padding="8px 13px 8px 13px"
                w="80px"
                isDisabled={isQuickSaving}
                onClick={onNext}
                variant="secondary"
                title="Review"
                iconRight={
                  <IconArrowRightSmall color={isQuickSaving ? '#CBD6E3' : 'black'} ml="6px" />
                }
              />
            </Center>
          </HintPopover>
        ) : null}
      </Hide>
    </Flex>
  );
}
