import { Icon } from '@chakra-ui/react';

const IconArrowRightSmall = ({ color = null, ...rest }) => (
  <Icon
    width="8px"
    height="12px"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1.81286 11.0364L1.84715 11.0687L1.88144 11.0364L7.18813 6.03639L7.22676 6L7.18813 5.96361L1.88144 0.963609L1.84715 0.931302L1.81286 0.963609L0.965712 1.7618L0.927088 1.79819L0.965712 1.83458L5.38663 6L0.965712 10.1654L0.927088 10.2018L0.965712 10.2382L1.81286 11.0364Z"
      fill={color || 'black'}
      stroke={color || 'black'}
      strokeWidth="0.1"
    />
  </Icon>
);

export default IconArrowRightSmall;
