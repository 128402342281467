import {
  IImageFileToImageRequest,
  IImageGenerationResponse,
  IImageUrlToImageRequest,
  IInpaintingRequest,
} from '@space-runners/ablo-ts-sdk';
import { ablo } from './ablo-wrapper';

export const photoTransformerFile = async (
  params: IImageFileToImageRequest,
  contentType: string
): Promise<IImageGenerationResponse> => {
  const response = await ablo().photoTransformer.fromFile(params, contentType);

  return response;
};

export const photoTransformerUrl = async (params: IImageUrlToImageRequest) => {
  const response = await ablo().photoTransformer.fromUrl(params);

  return response.images;
};

export const inpaint = async (params: IInpaintingRequest): Promise<IImageGenerationResponse> => {
  const response = await ablo().photoTransformer.inpaint(params);

  return response;
};
