import { Box, Center, Flex, Text, HStack, VStack, useToast } from '@chakra-ui/react';

import { Design } from '@/lib/types';

import { updateCartItem, deleteCartItem, getCheckoutUrl } from '@/api/cart';
import { getDesign } from '@/api/designs';
import Button from '@/components/button';

import { getFormattedAmount } from '../admin/utils/currency-formatter';

import { Cart, CartItem } from '@/components/types';

import NoReturnsBanner from './NoReturnsBanner';
import CartSummary from './CartSummary';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useMe } from '@/api/auth';

import { useQueryClient } from '@tanstack/react-query';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import LoadingOverlayDiv from '@/components/ui/LoadingOverlayDiv';
import CartDisclaimer from './CartDisclaimer';
import CartEmpty from './CartEmpty';

declare global {
  interface Window {
    r: {
      sendCheckoutEvent: (cart: string, id: string, url: string, aid: string, cs: string) => void;
    };
  }
}

const getCurrency = (cart: Cart, designsForCartItems: Record<string, Design>) =>
  designsForCartItems[cart.items[0].id]?.template?.currency?.name || 'USD';

export default function CartContent() {
  const { data: user } = useMe();

  const { cart } = user;

  const [designs, setDesigns] = useState<Record<string, Design>>({});

  const queryClient = useQueryClient();

  const toast = useToast();

  const handleUpdateCart = (cart) => {
    queryClient.setQueryData(['me'], { ...user, cart });
  };

  const [isLoading, setLoading] = useState(true);
  const [isWaiting, setWaiting] = useState(false);
  const [isPreparingCheckout, setPreparingCheckout] = useState(false);

  useEffect(() => {
    const { items } = cart;

    Promise.all(items.map((item) => getDesign(item.designId)))
      .then((designs) => {
        setDesigns(
          designs.reduce(
            (result, design, index) => ({
              ...result,
              [items[index].id]: design,
            }),
            {}
          )
        );
      })

      .finally(() => setLoading(false));
  }, []);

  const handleUpdateQuantity = (cartItem: CartItem, quantity: number) => {
    setWaiting(true);

    if (quantity === 0) {
      deleteCartItem(cartItem.id)
        .then(() => {
          const newItems = cart.items.filter(({ id }) => id !== cartItem.id);

          handleUpdateCart({
            ...cart,
            items: newItems,
          });
        })
        .finally(() => {
          setWaiting(false);
        });
    } else {
      updateCartItem(cartItem.id, quantity)
        .then((cartItem) =>
          handleUpdateCart({
            ...cart,
            items: cart.items.map((item) => {
              if (item.id !== cartItem.id) {
                return item;
              }

              return {
                ...item,
                quantity,
              };
            }),
          })
        )
        .finally(() => setWaiting(false));
    }
  };

  const handleGoToCheckout = async () => {
    setPreparingCheckout(true);

    try {
      const checkoutUrl = await getCheckoutUrl();

      window.location.href = checkoutUrl;
    } catch (e) {
      toast({
        title: 'Error creating checkout',
        status: 'error',
      });
    } finally {
      setPreparingCheckout(false);
    }
  };

  const totalPrice = cart.items.reduce((result, cartItem) => {
    const { price, quantity } = cartItem;

    return result + (price || 0) * quantity;
  }, 0);

  return (
    <VStack bg="#FFFFFF" justify="space-between" flex={1} margin="0 auto" w="100%" h="100%">
      <Box h="100%" w="100%">
        {Object.keys(designs).length > 0 && <NoReturnsBanner />}
        {isLoading ? (
          <Center h={{ base: '100%', md: 'calc(100% - 65px)' }} w="100%">
            <LoadingSpinner />
          </Center>
        ) : isEmpty(Object.keys(designs)) || isEmpty(cart.items) ? (
          <CartEmpty />
        ) : (
          <Box bg="#FFFFFF" pb="430px" w="100%">
            <CartSummary
              cart={cart}
              designs={designs}
              totalPrice={totalPrice}
              onSetQuantity={handleUpdateQuantity}
            />
            <Flex p="13px 16px" justify="space-between">
              <Text fontWeight={600} textStyle="subtitle">
                Total
              </Text>
              <HStack>
                <Text color="secondaryDarkGray.600" textStyle="overline">
                  {getCurrency(cart, designs)}
                </Text>
                <Text fontWeight={600} textStyle="subtitle">
                  {getFormattedAmount(getCurrency(cart, designs), totalPrice)}
                </Text>
              </HStack>
            </Flex>
          </Box>
        )}
      </Box>
      {Object.keys(designs).length > 0 && cart.items.length > 0 && (
        <Box bottom="52px" mt="23px" p="17px 14px 17px 14px" position="fixed">
          <CartDisclaimer />
          <Button isLoading={isPreparingCheckout} onClick={handleGoToCheckout} w="100%">
            Proceed to Checkout
          </Button>
        </Box>
      )}
      {isWaiting ? <LoadingOverlayDiv /> : null}
    </VStack>
  );
}
