import { useEffect } from 'react';

import { Box, useBreakpointValue } from '@chakra-ui/react';

import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { useIntercom } from 'react-use-intercom';

import { globalStyles } from '@/theme/styles';

import ROUTES from './routes';
import PUBLIC_ROUTES from './public-routes';

const {
  colors: { brand },
} = globalStyles;

export default function AdminDashboard({ me }) {
  const location = useLocation();

  const { boot, update } = useIntercom();

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (!me) {
      return;
    }

    const pathname = location.pathname;

    boot({
      actionColor: brand[500],
      backgroundColor: brand[600],
      email: me.email,
      userId: me.id,
    });

    update({ hideDefaultLauncher: isMobile && pathname.includes('/designs') });
  }, [me, boot, location.pathname, isMobile, update]);

  if (!me) {
    return PUBLIC_ROUTES.map((route, key) => {
      const { exact, path, component } = route;

      return <Route path={path} exact={exact} component={component} key={key} />;
    });
  }

  const allRoutes = ROUTES.reduce((result, route) => {
    if (route.routes) {
      return [...result, ...route.routes];
    }

    return [...result, route];
  }, []);

  return (
    <Box h="100%">
      <Switch>
        {allRoutes.map((route, key) => {
          const { exact, path, component } = route;

          return <Route path={path} exact={exact} component={component} key={key} />;
        })}
        <Redirect to="/" />
      </Switch>
    </Box>
  );
}
